import type { FC } from "react";
import type { Variant } from "@mui/material/styles/createTypography";
import { Skeleton, Typography } from "@mui/material";

interface IProps {
  loading?: boolean;
  label?: string | null;
  value?: string | number;
  component?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  variant?: Variant;
	subVariant?: Variant;
  align?: "right" | "left" | "center" | "inherit" | "justify";
}

const TextWithSkeleton: FC<IProps> = ({
  loading,
  label,
  value,
  component = "h6",
  variant = "body1",
	subVariant = "subtitle2",
  align = "left",
}) => {
  return (
    <>
      {label && (
        <Typography component={component} variant={variant} align={align}>
          {label}
        </Typography>
      )}
      <Typography
        component={component}
        variant={label ? subVariant : variant}
        align={align}
      >
        {loading ? (
          <Skeleton variant="text" sx={{ fontSize: "inherit" }} />
        ) : (
          value
        )}
      </Typography>
    </>
  );
};

export default TextWithSkeleton;
