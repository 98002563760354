import {
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from "@mui/material";
import { isEmpty } from "lodash";
import { Control, Controller, Path } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface IControlRadioGroupProps<R extends {}> {
  control: Control<R>;
  radioItems: {
    value: string;
    label: string;
  }[];
  name: Path<R>;
  required?: boolean;
  wrapperProps?: RadioGroupProps;
  checkedClassName?: string;
}

export const ControlRadioGroup = <R extends {}>({
  control,
  name,
  radioItems,
  required,
  wrapperProps = {},
  checkedClassName = "",
}: IControlRadioGroupProps<R> & RadioGroupProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "forms" });

  return (
    <Controller
      rules={{ required }}
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        const errorProps = fieldState.error
          ? {
              error: true,
              helperText: isEmpty(fieldState.error.message)
                ? t(`default.${fieldState.error.type}`)
                : fieldState.error.message,
            }
          : {};

        return (
          <>
            <RadioGroup {...wrapperProps} {...field}>
              {radioItems.map((radio) => (
                <FormControlLabel
                  key={radio.value}
                  value={radio.value}
                  control={<Radio />}
                  label={radio.label}
                  className={field.value === radio.value ? checkedClassName : ""}
                />
              ))}
            </RadioGroup>
            {errorProps.error && (
              <FormHelperText sx={{ color: "red" }}>
                {errorProps.helperText}
              </FormHelperText>
            )}
          </>
        );
      }}
    />
  );
};
