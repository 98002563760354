import cn from "classnames";
import styles from "./styles.module.scss";

export interface IRadioProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  title: string;
  variant: "Yes" | "No";
  register: any;
  value: string;
}

export const Radio: React.FC<IRadioProps> = ({
  title,
  variant,
  register,
  value,
}) => {
  return (
    <label className={cn(styles.c_radio, styles.c_radio + variant)}>
      <input type="radio" {...register} value={value} />
      <span className={styles.c_radio__title}>{title}</span>
    </label>
  );
};
