import { useParams } from "react-router-dom";
import { IssuerToken } from "../../../domain/specs/issuerToken";
import { PositiveRetailateResult } from "../../components/retailate/PositiveRetailateResult";
import { NotFound } from "../../components/NotFound";
import { DemoatomicActionSuccess } from "../../components/actionComponents/demoatomic/DemoatomicActionSuccess";

export const PositiveResultPage: React.FC = () => {
  const { issuerSysName } = useParams();

  if (issuerSysName?.toLowerCase() === IssuerToken.Retailete) {
    return <PositiveRetailateResult />;
  }

  if (issuerSysName?.toLowerCase() === IssuerToken.Demoatomic) {
    return <DemoatomicActionSuccess />;
  }

  return <NotFound />;
};
