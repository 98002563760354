import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, matchRoutes } from "react-router-dom";
import { init } from "./diContainer/initializeDependencies";
import { getRouter } from "./diContainer/getDependencies";
import { initI18n } from "./adapters/i18n/i18n";
import {
  initializeFaro,
  getWebInstrumentations,
  ReactIntegration,
  ReactRouterVersion,
} from "@grafana/faro-react";
import { isTelegramWebview, isWebView } from "./utils/utils";
import { initAnalyticsService } from "./adapters/services/analyticsService";
import LoaderPage from "./view/pages/LoaderPage";

init();
initAnalyticsService();
initI18n();

export const faro = initializeFaro({
  url: `${process.env.REACT_APP_FARO_COLLECTOR_URL}`,
  app: {
    name: "loyalty-web-card",
    version: `${process.env.REACT_APP_VERSION}`,
    environment: `${process.env.REACT_APP_ENVIRONMENT}`,
  },
  user: {
    attributes: {
      isWebView: String(isWebView()),
      userAgent: String(navigator.userAgent),
      isTelegramWebView: String(isTelegramWebview()),
      isHybridApp: String(!!window.ReactNativeWebView)
    },
  },
  sessionTracking: {
    samplingRate: 1, // 0.5 etc = % of captured sessions
  },
  instrumentations: [
    // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
    ...getWebInstrumentations(),

    new ReactIntegration({
      router: {
        version: ReactRouterVersion.V6_data_router,
        dependencies: {
          matchRoutes,
        },
      },
    }),

    // Initialization of the tracing package.
    // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
    // new TracingInstrumentation(),
  ],
});

const router = getRouter();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<LoaderPage />} />
  </React.StrictMode>
);
