import { Banner } from "./Banner/Banner";
import { Info } from "./Info/Info";
import { Wrapper } from "./Wrapper/Wrapper";
import resultImgUrl from "./images/result.svg";

export const NegativeRetailateResult: React.FC = () => {
  return (
    <Wrapper>
      <Banner />
      <Info
        imgSrc={resultImgUrl}
        title="Дякуємо!"
        text="Будь-яка відповідь допомагає нам ставати краще. Сподіваємось, наступного разу у нас буде шанс змінити  вашу думку!"
      />
    </Wrapper>
  );
};
