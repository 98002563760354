import { StateSubject } from "@roketus/web-toolkit";
import { get, isUndefined } from "lodash";
import type {
  ISignupService,
  ISignupServiceData,
} from "../../boundary/ISignUpService";
import {
  getActionService,
  getAuthService,
  getIssuerConfigService,
  getRouterService,
  getSdk,
  getSigninService,
} from "../../diContainer/getDependencies";
import {
  CustomCodeError,
  // EC_SIGNUP_RESEND_CODE,
  EC_SIGNUP_SET_PHONE,
  // EC_SIGNUP_SET_PROFILE,
} from "../../domain/specs/errorCodes";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import {
  isAxiosError,
  isUserAlreadyRegisteredError,
} from "../../utils/response-errors";
import { IRegStepResponseAttributes } from "@roketus/loyalty-end-user-js-sdk";

export const stateMachine = new StateSubject<ISignupServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
});

const ACTIVATION_MODE = "50a7f0c9";

export const signupService: ISignupService = {
  setPhoneNumber: (phoneNumber) => {
    stateMachine.setState({
      phoneNumber,
    });
  },

  setPhone: async (phoneNumber, params) => {
    pushLogToFaro("Sign up: set phone");

    const sdk = getSdk();
    const routerService = getRouterService();
    const authService = getAuthService();

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
      errorMessage: "",
    });

    try {
      const { data } = await sdk.signup.setPhone(
        ACTIVATION_MODE,
        phoneNumber.replace(/\s+/g, ""),
        params
      ).result;

      const token = data.user?.token;
      const refreshToken = data.user?.refresh;

      if (!isUndefined(token)) {
        authService.setTokens(token, refreshToken);
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        phoneNumber,
      });

      pushLogToFaro("Success: set phone on sign up");

      routerService.navigateToNextRegStep(data);

      return null;
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setPhone",
        message: "Error: failed to set phone on sign up",
      });

      if (isAxiosError(e)) {
        const errorText = get(e, "response.data.data[0].title", "unknownText");

        if (isUserAlreadyRegisteredError(e)) {
          const signInService = getSigninService();

          signInService.setPhoneNumberOTP(phoneNumber);

          stateMachine.setState({
            loading: false,
            isLoaded: true,
            failed: false,
          });

          pushLogToFaro("Sign up: redirecting to signin flow", {
            issuer: String(getIssuerConfigService().getIssuer()),
          });

          routerService.navigateToIssuerPath("/signin/validate-otp");

          return null;
        }

        stateMachine.setState({
          errorMessage: errorText,
        });
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      return new CustomCodeError(EC_SIGNUP_SET_PHONE);
    }
  },

  setCode: async (code, setError) => {
    pushLogToFaro("Sign up: set OTP");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const routerService = getRouterService();

    try {
      await sdk.signup.phoneActivation(ACTIVATION_MODE, parseInt(code, 10))
        .result;
      pushLogToFaro("Success: phone activation on signup");

      const { data } = await sdk.signup.regWithTerms(ACTIVATION_MODE, true)
        .result;
      pushLogToFaro("Success: registration with terms");

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setCode",
        message: "Error: failed to set OTP on signup",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      const errorText = get(e, "response.data.data[0].title", "unknownText");

      setError("otp", { message: errorText, type: errorText });
    }
  },

  resendCode: async () => {
    pushLogToFaro("Sign up: resend OTP on signup");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();

    try {
      await sdk.phone.resendActivationCode().result;

      pushLogToFaro("Success: resend OTP on signup");

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "resendCode",
        message: "Error: failed to resend OTP on signup",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  setProfile: async (firstName: string, lastName: string) => {
    pushLogToFaro("Sign up: set profile");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const routerService = getRouterService();
    const action = getActionService();
    const authService = getAuthService();

    try {
      const { data } = await sdk.signup.setProfile(ACTIVATION_MODE, {
        firstName,
        lastName,
      }).result;

      authService.setIsAuthenticated();

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: set profile", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setProfile",
        message: "Error: failed to set profile",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  skipStep: async (
    step: string,
    additionalRequest?: () => Promise<IRegStepResponseAttributes>
  ) => {
    pushLogToFaro(`Sign up: skip step ${step}`);

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getSdk();
    const routerService = getRouterService();

    try {
      let data: IRegStepResponseAttributes | null = null;

      ({ data } = await sdk.signup.skipStep(ACTIVATION_MODE, step).result);

      if (additionalRequest) {
        data = await additionalRequest();
      }

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });
    } catch (e) {
      pushErrorToFaro(e, {
        message: `Error: failed to skip step ${step}`,
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });
    }
  },

  skipPhoneActivationStep: async () => {
    const sdk = getSdk();

    const additionalRequest = async () => {
      const { data } = await sdk.signup.regWithTerms(ACTIVATION_MODE, true)
        .result;
      pushLogToFaro("Success: registration with terms");

      return data;
    };

    await signupService.skipStep("phoneActivation", additionalRequest);
  },

  skipProfileStep: async () => {
    await signupService.skipStep("profile");
  },

  data$: stateMachine.state$,
};
