import { useState, useEffect, FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Tooltip, Typography } from "@mui/material";

const RESEND_SMS_TIMEOUT = 30;

interface IProps {
  handleResend: () => void;
}

export const ResendOtpButton: FC<IProps> = ({ handleResend }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "signIn.validateOtp",
  });

  const [resendInProgress, setResendInProgress] = useState(true);
  const [timer, setTimer] = useState<number | null>(RESEND_SMS_TIMEOUT);

  const handleResendClick = () => {
    setResendInProgress(true);
    setTimer(RESEND_SMS_TIMEOUT);

    handleResend();
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (resendInProgress && timer !== null) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer ? prevTimer - 1 : 0));
      }, 1000);
    }

    if (timer === 0) {
      setResendInProgress(false);
      setTimer(null);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [resendInProgress, timer]);

  return (
    <>
      {resendInProgress ? (
        <Typography variant="subtitle1" align="right" lineHeight={1.2}>
          {t("waitResendStart")} {timer} {t("waitResendEnd")}
        </Typography>
      ) : (
        <Tooltip title={t("noCode")}>
          <Button variant="outlined" onClick={handleResendClick}>
            {t("resendCode")}
          </Button>
        </Tooltip>
      )}
    </>
  );
};
