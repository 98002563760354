import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useObservable } from "@roketus/web-toolkit";

import { PhoneInput } from "../../components/form/PhoneInput";
import { LayoutHeadless } from "../../components/layouts/LayoutHeadless";
import { Avatar, Box, Grid, Typography } from "@mui/material";
import { Link } from "../../components/Link";
import { isEmpty, isString } from "lodash";
import { isValidPhoneNumber } from "../../../adapters/helpers";
import { useQuery } from "../../../adapters/hooks/useQuery";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import {
  getIssuerConfigService,
  getMessageBus,
  getSignupService,
} from "../../../diContainer/getDependencies";
import { SignupButtonWithLoading } from "../../components/SignupButtonWithLoading";
import { LoyaltyPolicy } from "../../components/LoyaltyPolicy";

const SEND_CODE_DELAY = 5;

export const SignUpPage: FC = () => {
  const { handleSubmit, control, setError, setValue, watch } = useForm<{
    phone: string;
  }>();

  const query = useQuery();
  const { issuerSysName } = useParams();

  const delay = useRef(0);

  const { t } = useTranslation("translation", { keyPrefix: "signUp.setPhone" });

  const [termsChecked, setTermsChecked] = useState(false);
  const [delayText, setDelayText] = useState(0);

  const issuerConfigService = getIssuerConfigService();
  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const data = useObservable(issuerConfigService.data$);
  const signupData = useObservable(signupService.data$);

  const runDelay = useCallback(() => {
    delay.current = SEND_CODE_DELAY;
    const interval = setInterval(() => {
      if (delay.current === 0) {
        clearInterval(interval);
        return;
      }
      delay.current--;
      setDelayText(delay.current);
    }, 1000);
  }, []);

  useEffect(() => {
    if (!isEmpty(signupData?.errorMessage)) {
      setError("phone", { message: signupData?.errorMessage });
    }
  }, [runDelay, setError, signupData?.errorMessage]);

  useEffect(() => {
    if (
      signupData &&
      isString(signupData.phoneNumber) &&
      !isEmpty(signupData.phoneNumber)
    ) {
      setValue("phone", signupData.phoneNumber);
    }
  }, [signupData?.phoneNumber, setValue]);

  const onSubmit = (data: any) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignUpButtonClick",
      source: "SignUpPage",
    });
    messageBus.send(analyticEvent);

    const refCode = query.get("refCode") ?? undefined;

    runDelay();

    signupService.setPhone(data.phone, { refCode });
  };

  const canSignUp = termsChecked && delay.current === 0;
  const phoneNumberValue = watch("phone");
  const isDisabled = !isValidPhoneNumber(phoneNumberValue) || !canSignUp;

  const countdownTitle = delayText > 0 ? delayText : "";

  return (
    <LayoutHeadless>
      <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h4">
        {t("header")}
      </Typography>
      {data?.config?.companyName && (
        <Typography variant="subtitle1" align="center" my={2}>
          {t("company")}: {data.config.companyName}
        </Typography>
      )}
      <Typography variant="body1" align="center">
        {t("text")}
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3, display: "block", width: "100%" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PhoneInput control={control} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <LoyaltyPolicy
              termsChecked={termsChecked}
              setTermsChecked={setTermsChecked}
            />
          </Grid>
        </Grid>
        <SignupButtonWithLoading
          onClick={handleSubmit(onSubmit)}
          disabled={isDisabled}
        >
          {t("buttonLabel", { countdownTitle })}
        </SignupButtonWithLoading>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Link
              to={`/${issuerSysName}/signin`}
              data-testid="signin"
              variant="body2"
            >
              {t("hasAccount")}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </LayoutHeadless>
  );
};
