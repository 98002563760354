import { IPostSurveyDTO } from "@roketus/loyalty-end-user-js-sdk";
import { ISurveyFormPayload } from "../../../boundary/forms/IFreeSurveyFormData";
import { toSurveyPayloadDTO } from "./toSurveyDTO";

export const toPostSurveyPayloadDTO = (
  issuerSysName: string,
  identity: {
    authorized: boolean;
    jwt: string;
  },
  surveyFormData: ISurveyFormPayload,
): IPostSurveyDTO => {
	const surveyPayload = toSurveyPayloadDTO(surveyFormData);

  return {
    issuer: issuerSysName,
    version: 1,
    identity,
    ...surveyPayload,
  };
};
