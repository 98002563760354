import { LoaderFunctionArgs } from "react-router-dom";
import {
  ACTION_CAMPAIGN_SEARCH_PARAM,
  ACTION_DISPATCH_SEARCH_PARAM,
} from "../actionsRoutes";
import { IActionService } from "../../../boundary/IActionService";
import { isInAppBrowser } from "../../../utils/utils";

export const createActionLoader =
  (actionService: IActionService) => async (props: LoaderFunctionArgs) => {
    if (isInAppBrowser()) {
      return null;
    }

    const { url: requestUrl } = props.request;

    const url = new URL(requestUrl);
    const actionType = url.pathname.split("/").at(-1);
    const querySearchParams = url.searchParams;
    const campaign = querySearchParams.get(ACTION_CAMPAIGN_SEARCH_PARAM);
    const dispatchKey = querySearchParams.get(ACTION_DISPATCH_SEARCH_PARAM);

    actionService.storeAction({
      actionType: actionType || "",
      dispatchKey: dispatchKey || "",
      campaign: campaign || "",
    });

    return null;
  };
