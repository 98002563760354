import { StateSubject } from "@roketus/web-toolkit";
import { MetaAttributes } from "@grafana/faro-react";
import { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import {
  IProfileService,
  IProfileServiceData,
} from "../../boundary/IProfileService";
import { toPromoCodesEntity } from "../data/promoCodes/toPromoCodesEntities";
import { toPassAndBarcodeEntities } from "../data/toPassAndBarcodeEntities";
import { EC_FETCH_PROFILE_INFO } from "../../domain/specs/errorCodes";
import { fetchPassDataByCardNumber } from "../api/fetchPassDataByCardNumber";
import {
  pushErrorToFaro,
  pushLogToFaro,
  setUserAttributesMetaToFaro,
  setUserMetaToFaro,
} from "../../utils/faroLogs";
import { createDispatchError } from "../helpers";
import {
  getErrorTextFromResponse,
  isNoCardError,
} from "../../utils/response-errors";
import {
  getAuthService,
  getRouterService,
} from "../../diContainer/getDependencies";
import { checkIfProfileActivated } from "../../utils/registration";

const dispatchError = createDispatchError("profileService");

const stateMachine = new StateSubject<IProfileServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  profile: null,
  isProfileActivated: false,
  promoCodes: [],
  barcodeInfo: null,
  passInfo: null,
});

export const init = function (sdk: ISDK): IProfileService {
  const clearState: IProfileService["clearState"] = () => {
    stateMachine.setState({
      loading: false,
      isLoaded: false,
      failed: false,
      profile: null,
      isProfileActivated: false,
      promoCodes: [],
      barcodeInfo: null,
      passInfo: null,
    });
  };

  const getProfileInfo: IProfileService["getProfileInfo"] = async () => {
    try {
      const { data: profile } = await sdk.profile.getProfileInfo().result;

      stateMachine.setState({
        profile,
        isProfileActivated: checkIfProfileActivated(profile),
      });

      setUserMetaToFaro(
        "id",
        profile.profile[0]?.id as string & MetaAttributes
      );

      return profile;
    } catch (e) {
      pushErrorToFaro(e, {
        message: "Error: failed to fetch profile info",
      });

      throw e;
    }
  };

  const getFullProfileData: IProfileService["getFullProfileData"] =
    async () => {
      stateMachine.setState({
        loading: true,
        isLoaded: false,
        failed: false,
      });

      try {
        const { data: card } = await sdk.card.getCardInfo().result;

        // Add card number to faro logs
        setUserAttributesMetaToFaro(
          "cardNumber",
          card?.CardNumber as string & MetaAttributes
        );

        const { data: bonusesData } = await sdk.balance.getBonuses().result;
        const passData = await fetchPassDataByCardNumber(card?.CardNumber);

        const [passInfo, barcodeInfo] = toPassAndBarcodeEntities(
          passData,
          card?.CardNumber
        );

        const promoCodes = toPromoCodesEntity(card?.Promocodes || []);

        stateMachine.setState({
          loading: false,
          isLoaded: true,
          fullName: card?.NameOnCard,
          discount: card?.BonusPercent,
          cardNumber: card?.CardNumber,
          bonusBalance:
            bonusesData?.totalActive.UAH ||
            bonusesData?.totalActive.USD ||
            bonusesData?.totalActive.EUR ||
            0,
          barcodeInfo,
          passInfo,
          promoCodes,
        });
      } catch (e) {
        stateMachine.setState({
          loading: false,
          isLoaded: false,
        });

        pushErrorToFaro(e, {
          message: "Error: failed to fetch full profile info",
        });

        const message = "Failed to fetch full profile info";
        dispatchError({
          code: EC_FETCH_PROFILE_INFO,
          error: e,
          message,
        });

        if (isNoCardError(e)) {
          const authService = getAuthService();
          const routerService = getRouterService();

          authService.removeTokens();
          routerService.navigateToIssuerPath("/signup");
        }
      }
    };

  const resendActivationCode: IProfileService["resendActivationCode"] =
    async () => {
      pushLogToFaro("Profile: resend OTP for activation");

      stateMachine.setState({
        loading: true,
      });

      try {
        await sdk.phone.resendActivationCode().result;

        pushLogToFaro("Success: resend OTP for activation");

        stateMachine.setState({
          loading: false,
        });
      } catch (e) {
        pushErrorToFaro(e, {
          action: "resendActivationCode",
          message: "Error: failed to resend OTP for activation",
        });

        stateMachine.setState({
          loading: false,
        });
      }
    };

  const verifyProfile = async () => {
    pushLogToFaro("Profile: verifying profile");

    const routerService = getRouterService();

    try {
      resendActivationCode();

      routerService.navigateToIssuerPath("/profile/activation");
    } catch (e) {
      pushErrorToFaro(e, {
        action: "veryfyProfile",
        message: "Error: failed to verify profile",
      });
    }
  };

  const activateProfileByOTP: IProfileService["activateProfileByOTP"] = async (
    code,
    setError
  ) => {
    pushLogToFaro("Profile: send OTP");

    stateMachine.setState({
      loading: true,
    });

    const routerService = getRouterService();

    try {
      const { data: profile } = await sdk.phone.checkActivationCode(
        parseInt(code, 10)
      ).result;
      pushLogToFaro("Success: profile activation");

      stateMachine.setState({
        loading: false,
        profile,
        isProfileActivated: checkIfProfileActivated(profile),
      });

      routerService.navigateToIssuerPath("/");
    } catch (e) {
      pushErrorToFaro(e, {
        action: "activateProfileByOTP",
        message: "Error: failed to activate profile with OTP",
      });

      stateMachine.setState({
        loading: false,
      });

      const errorText = getErrorTextFromResponse(e);

      setError("otp", { message: errorText, type: errorText });
    }
  };

  return {
    data$: stateMachine.state$,
    clearState,
    getProfileInfo,
    getFullProfileData,
    verifyProfile,
    resendActivationCode,
    activateProfileByOTP,
  };
};
