import React from "react";
import { TextField, TextFieldProps } from "@mui/material";
import { Control, Controller, Path, PathValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { RegisterOptions } from "react-hook-form/dist/types/validator";

interface IProps<R extends {}> {
  name: Path<R>;
  defaultValue?: PathValue<R, Path<R>>;
  control: Control<R>;
  rules?: Omit<
    RegisterOptions<R, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

export const Input = <R extends {}>({
  name,
  control,
  rules,
  defaultValue,
  ...props
}: IProps<R> & TextFieldProps) => {
  const { t } = useTranslation("translation", { keyPrefix: "forms" });

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const errorProps = fieldState.error
          ? {
              error: true,
              helperText: isEmpty(fieldState.error.message)
                ? t(`default.${fieldState.error.type}`)
                : fieldState.error.message,
            }
          : {};

        return (
          <TextField
            {...props}
            {...field}
            required={!!rules?.required}
            defaultValue={defaultValue}
            {...errorProps}
          />
        );
      }}
    />
  );
};
