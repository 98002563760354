import { HTTPStatic, IHTTPClientResponse } from "@roketus/web-toolkit";
import { IPassDTO } from "../../boundary/IProfileService";

export const fetchPassDataByCardNumber = async function (
  cardNumber: string
): Promise<IPassDTO | null> {
  try {
    const client = HTTPStatic.create({
      staticConfig: {
        baseURL: process.env.REACT_APP_API_URL,
      },
    });
    const response: IHTTPClientResponse<{
      data: IPassDTO;
    }> = await client.get(`/api/barcode/byalttext/${cardNumber}`);

    return response?.data?.data || null;
  } catch (e) {
    return null;
  }
};
