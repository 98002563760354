import { Banner } from "./Banner/Banner";
import { Info } from "./Info/Info";
import { Wrapper } from "./Wrapper/Wrapper";
import resultImgUrl from "./images/result.svg";

export const PositiveRetailateResult: React.FC = () => {
  return (
    <Wrapper>
      <Banner />
      <Info
        imgSrc={resultImgUrl}
        title="Дякуємо за ваш час та важливу відповідь!"
        text="Ваші відгуки допомагають нам покращувати якість наших послуг"
      />
    </Wrapper>
  );
};
