import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Grid } from "@mui/material";
import {
  getIssuerConfigService,
  getProfileService,
} from "../../../diContainer/getDependencies";
import { ProfileLayout } from "../../components/layouts/ProfileLayout";
import BarcodeBox from "../../components/BarcodeBox/BarcodeBox";
import DownloadCardBox from "../../components/DownloadCardBox/DownloadCardBox";
import PromoCodesBox from "../../components/PromoCodesBox";
import ReferralProgramBox from "../../components/ReferralProgramBox/ReferralProgramBox";
import TextWithSkeleton from "../../components/TextWithSkeleton";
import ActivationWarning from "../../components/ActivationWarning";

export const ProfilePage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const profileService = getProfileService();
  const issuerConfigService = getIssuerConfigService();

  const profileData = useObservable(profileService.data$);
  const issuerConfigData = useObservable(issuerConfigService.data$);

  useEffect(() => {
    profileService.getFullProfileData();
  }, [profileService]);

  const handleActivation = () => {
    if (!profileData?.profile?.phone) return;

    profileService.verifyProfile();
  };

  const isLoading = !profileData || profileData.loading;

  return (
    <ProfileLayout heading={issuerConfigData?.config?.companyName}>
      <Grid container spacing={2}>
        {!profileData?.isProfileActivated && (
          <Grid item xs={12}>
            <ActivationWarning handleActivation={handleActivation} />
          </Grid>
        )}
        <Grid item xs={6}>
          <TextWithSkeleton
            loading={isLoading}
            label={t("organizationName")}
            value={profileData?.passInfo?.organizationName}
            component="h6"
            variant="h6"
            subVariant="h6"
          />
        </Grid>
        <Grid item xs={6}>
          <TextWithSkeleton
            loading={isLoading}
            label={t("description")}
            value={profileData?.passInfo?.description}
            component="h6"
            variant="h6"
            subVariant="h6"
            align="right"
          />
        </Grid>
        <Grid item xs={12}>
          <TextWithSkeleton
            loading={isLoading}
            value={profileData?.fullName}
            component="h1"
            variant="h5"
            align="center"
          />
        </Grid>
        <Grid item xs={6}>
          <TextWithSkeleton
            loading={isLoading}
            label={t("balanceLabel")}
            value={profileData?.bonusBalance}
            component="h5"
            variant="body1"
          />
        </Grid>
        <Grid item xs={6}>
          <TextWithSkeleton
            loading={isLoading}
            label={t("discountLabel")}
            value={`${profileData?.discount}%`}
            component="h5"
            variant="body1"
            align="right"
          />
        </Grid>
        <Grid item xs={12}>
          <TextWithSkeleton
            loading={isLoading}
            label={t("cardNumberLabel")}
            value={profileData?.cardNumber}
            variant="h5"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <BarcodeBox
            loading={isLoading}
            barcodeInfo={profileData?.barcodeInfo}
          />
        </Grid>

        <Grid item container xs={12} md={6} gap={2}>
          <DownloadCardBox />

          <ReferralProgramBox
            loading={isLoading}
            refCode={profileData?.profile?.refCode}
          />

          <PromoCodesBox
            loading={isLoading}
            promoCodes={profileData?.promoCodes}
          />
        </Grid>
      </Grid>
    </ProfileLayout>
  );
};
