import { FC, ReactElement } from "react";
import { Trans } from "react-i18next";
import { Box } from "@mui/material";
import logo from "../../../images/atomic-logo.svg";
import styles from "./styles.module.scss";

interface IProps {
  icon: string;
  title: string;
  image?: string;
  imageProps?: Record<string, string>;
  className?: string;
  children?: ReactElement;
}

export const DemoatomicLayout: FC<IProps> = ({
  icon,
  title,
  image,
  imageProps,
  className,
  children,
}) => {
  return (
    <Box
      className={`${styles.pageWrapper} ${className ? styles[className] : ""}`}
    >
      <div className={styles.header}>
        <img src={logo} alt="Atomic Loyalty Logo" className={styles.logo} />
      </div>
      <div className={styles.content}>
        <div className={styles.contentCirkle}></div>
        <img src={icon} alt="Icon" className={styles.contentIcon} />
        <h1 className={styles.contentTitle}>
          <Trans
            i18nKey={`action.demoatomic.${title}`}
            components={{
              span: <span className={styles.accentText} />,
              b: <b className={styles.accentLargeText} />,
              br: <br />
            }}
          />
        </h1>
        {children}
      </div>
      {image && (
        <div
          className={styles.contentImage}
          style={{
            backgroundImage: `url(${image})`,
            ...imageProps,
          }}
        ></div>
      )}
    </Box>
  );
};
