import { sdk } from "@roketus/loyalty-end-user-js-sdk";
import { initContainer } from "./container";
import {
  persistentStorage,
  sessionStorage,
  MessageBus,
  ErrorMessageBus,
} from "@roketus/web-toolkit";
import type { IRegistry } from "../boundary/IRegistry";
import { init as initIssuerConfigService } from "../adapters/services/issuerConfigService";
import { init as initAppStylingService } from "../adapters/services/appStylingService";
import { init as initSignInService } from "../adapters/services/signInService";
import { createRouter } from "../adapters/router/router";
import { signupService } from "../adapters/services/signupService";
import { init as initProfileService } from "../adapters/services/profileService";
import { cardService } from "../adapters/services/cardService";
import { actionService } from "../adapters/services/actionService";
import { googleAnalyticsService } from "../adapters/services/googleAnalyticsService";
import { init as initLocalizationService } from "../adapters/services/localizationService";
import { init as initAuthService } from "../adapters/services/authService";
import { init as initRouterService } from "../adapters/services/routerService";

declare global {
  interface Window {
    // Below just informs IDE and/or TS-compiler (it's set in `.js` file).
    rkr: IRegistry;
    gtag: (...args: any[]) => void;
  }
}

export const init = function (): void {
  const messageBus = new MessageBus();
  const errorMessageBus = new ErrorMessageBus(messageBus);
  sdk.init({
    envUrl: process.env.REACT_APP_LOYALTY_API_URL,
    surveyApiUrl: `${process.env.REACT_APP_SURVEY_API_GATEWAY}/${process.env.REACT_APP_SURVEY_ENV}`,
    surveyApiKey: process.env.REACT_APP_SURVEY_WRITE as string,
    messageBus,
  });
  const issuerConfigService = initIssuerConfigService(sdk);
  const profileService = initProfileService(sdk);
  const authService = initAuthService(sdk, messageBus, profileService);
  const router = createRouter({
    issuerConfigService,
    storage: persistentStorage,
    profileService,
    actionService,
    authService,
  });
  const routerService = initRouterService(sdk);

  const registry: IRegistry = {
    persistentStorage,
    sessionStorage,
    messageBus,
    errorMessageBus,
    sdk,
    authService,
    actionService,
    issuerConfigService,
    signInService: initSignInService(),
    router,
    routerService,
    signupService,
    profileService,
    cardService,
    appStylingService: initAppStylingService(issuerConfigService),
    googleAnalyticsService,
    localizationService: initLocalizationService(issuerConfigService),
  };

  if (process.env.REACT_APP_ENVIRONMENT === "develop") {
    window.rkr = registry;
  }

  initContainer(registry);
};
