import { StateSubject } from "@roketus/web-toolkit";
import { I18N_STORAGE_KEY, i18n } from "../i18n/i18n";
import {
  ILocalizationService,
  ILocalizationServiceData,
} from "../../boundary/ILocalizationService";
import {
  IIssuerConfigData,
  IIssuerConfigService,
} from "../../boundary/IIssuerConfigService";
import { IssuerParams } from "../../domain/specs/IssuerParams";
import { EC_FAILED_TO_SET_LOCALE } from "../../domain/specs/errorCodes";

// ISO standards:
// https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
// https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes

// Locale in localStorage
// is handled by i18next-browser-languagedetector (i18n config); key: i18nextLng
// https://github.com/i18next/i18next-browser-languageDetector

export const DEFAULT_COUNTRY_CODE = "US";
export const DEFAULT_LANGUAGE_CODE = "en";

const stateMachine = new StateSubject<ILocalizationServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  country: DEFAULT_COUNTRY_CODE, // ISO country code
  language: DEFAULT_LANGUAGE_CODE, // ISO lang code
});

const setLocaleFromConfig = async (
  config: Partial<Record<IssuerParams, string>>
) => {
  try {
    if (!i18n.isInitialized) return null;

    const country = config.country;
    
    const language = localStorage.getItem(I18N_STORAGE_KEY) || config.language;

    await i18n.changeLanguage(language);

    stateMachine.setState({
      country: country || DEFAULT_COUNTRY_CODE,
      language: language || DEFAULT_LANGUAGE_CODE,
    });

    return null;
  } catch (e) {
    if (e instanceof Error) {
      return e;
    }
    return new Error("Exception: localization Unknown error");
  }
};

const setLocale = async (language = "en") => {
  try {
    if (!i18n.isInitialized) return null;
    
    localStorage.setItem(I18N_STORAGE_KEY, language);
    await i18n.changeLanguage(language);

    stateMachine.setState({
      language,
    });

    return null;
  } catch (e) {
    if (e instanceof Error) {
      return e;
    }
    return new Error("Exception: localization Unknown error");
  }
};

export const init = (
  issuerConfigService: IIssuerConfigService
): ILocalizationService => {
  issuerConfigService.data$.subscribe(async (data: IIssuerConfigData) => {
    if (data.failed) {
      return;
    }

    const result = await setLocaleFromConfig(data.config);

    if (result instanceof Error) {
      console.error(EC_FAILED_TO_SET_LOCALE);
    }
  });

  return {
    setLocale,
    setLocaleFromConfig,
    data$: stateMachine.state$,
  };
};
