import Box from "@mui/material/Box";
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const l21Animation = keyframes`
  50%  {box-shadow: 19px 0 0 3px, 38px 0 0 7px, 57px 0 0 3px;}
  100% {box-shadow: 19px 0 0 0, 38px 0 0 3px, 57px 0 0 7px;}
`;

const StyledLoader = styled.div`
  color: #0FA827;
  width: 4px;
  aspect-ratio: 1;
  border-radius: 50%;
  box-shadow: 19px 0 0 7px, 38px 0 0 3px, 57px 0 0 0;
  transform: translateX(-38px);
  animation: ${l21Animation} 0.5s infinite alternate linear;
`;

const LoaderPage = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <StyledLoader />;
    </Box>
  );
};

export default LoaderPage;
