import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import CloseIcon from "@mui/icons-material/Close";
import { buildAnalyticMessageEntity } from "../../domain/entities/analyticEntity";
import { getMessageBus, getSigninService } from "../../diContainer/getDependencies";

interface IProps {}

const LogOut: FC<IProps> = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const signInService = getSigninService();
  const messageBus = getMessageBus();

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleLogout = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "LogOutButtonClick",
      source: "ProfilePage",
    });
    messageBus.send(analyticEvent);

    signInService.logOut();
    setOpenConfirm(false);
  };

  return (
    <>
      <Tooltip title={t("logout")}>
        <IconButton
          size="large"
          aria-label={t("logout") || ""}
          color="inherit"
          onClick={() => setOpenConfirm(true)}
        >
          <LogoutIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={openConfirm}
        keepMounted
        aria-labelledby="confirmation-dialog-title"
        onClose={() => setOpenConfirm(false)}
        sx={{ "& .MuiPaper-root": { margin: 0 } }}
      >
        <DialogTitle
          id="confirmation-dialog-title"
          sx={{
            p: 1,
            pt: 5,
            width: "340px",
            textAlign: "center",
          }}
        >
          {t("logoutConfirm")}
          <IconButton
            aria-label="close"
            onClick={() => setOpenConfirm(false)}
            sx={{
              position: "absolute",
              top: 0,
              right: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button color="success" onClick={handleLogout}>
            {t("yes")}
          </Button>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Button color="error" onClick={() => setOpenConfirm(false)}>
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default LogOut;
