import type { FC } from "react";
import { Paper, Skeleton } from "@mui/material";
import type { IBarcodeInfo } from "../../../boundary/IProfileService";
import { Barcode } from "./Barcode";
import { AlterBarcodeText } from "./AlterBarcodeText";

interface IProps {
  loading?: boolean;
  barcodeInfo?: IBarcodeInfo | null;
}

const BarcodeBox: FC<IProps> = ({ loading, barcodeInfo }) => {
  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded" width="100%" height={195} />
    );
  }

  if (!barcodeInfo) return null;

  return (
    <Paper sx={{ p: 4 }}>
      <Barcode text={barcodeInfo.text} type={barcodeInfo.type} />

      {barcodeInfo.alterText && (
        <AlterBarcodeText text={barcodeInfo.alterText} />
      )}
    </Paper>
  );
};

export default BarcodeBox;
