export type TCustomErrorType = string;
export class CustomCodeError extends Error {
  public extraInfo?: Record<string, any>;
  
  constructor(message: string, extraInfo?: Record<string, any>) {
    super(message);
    
    this.name = this.constructor.name;
    this.extraInfo = extraInfo;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export const EC_AUTH_NO_AUTHORIZED: TCustomErrorType = "EC_AUTH_NO_AUTHORIZED";
export const EC_AUTH_NO_COMPLETED: TCustomErrorType = "EC_AUTH_NO_COMPLETED";
export const EC_NO_USER: TCustomErrorType = "ERROR_NO_USER";
export const EC_NO_CARD: TCustomErrorType = "ERROR_NO_CARD";
export const EC_FAILED_FETCH_CONFIG_DATA: TCustomErrorType =
  "EC_FAILED_FETCH_CONFIG_DATA";
export const EC_FAILED_TO_DOWNLOAD_CARD: TCustomErrorType =
  "EC_FAILED_TO_DOWNLOAD_CARD";
export const EC_FAILED_TO_SET_LOCALE: TCustomErrorType =
  "EC_FAILED_TO_SET_LOCALE";

export const EC_NOT_FOUND: TCustomErrorType = "EC_NOT_FOUND";
export const EC_INVALID_ISSUER_SYS_NAME: TCustomErrorType =
  "EC_INVALID_ISSUER_SYS_NAME";
export const EC_EMPTY_CONFIG: TCustomErrorType = "EC_EMPTY_CONFIG";
export const EC_FETCH_PROFILE_INFO: TCustomErrorType = "EC_FETCH_PROFILE_INFO";
export const EC_LOGIN_INVALID_PHONE: TCustomErrorType =
  "EC_LOGIN_INVALID_PHONE";
export const EC_LOGIN_EMPTY_PHONE: TCustomErrorType = "EC_LOGIN_EMPTY_PHONE";
export const EC_LOGIN_EMPTY_JWT: TCustomErrorType = "EC_LOGIN_EMPTY_JWT";
export const EC_SIGNUP_SET_PHONE: TCustomErrorType = "EC_SIGNUP_SET_PHONE";
export const EC_SIGNUP_SET_CODE: TCustomErrorType = "EC_SIGNUP_SET_CODE";
export const EC_SIGNUP_RESEND_CODE: TCustomErrorType = "EC_SIGNUP_RESEND_CODE";
export const EC_SIGNUP_SET_PROFILE: TCustomErrorType = "EC_SIGNUP_SET_PROFILE";

export const USER_REGISTERED_ERROR_CODE = "2b12f3e6-01";

// TODO add possible errors
export const actionErrorCodes = {
  "7f6c65fc-001": "promoCodeNotFound",
  "581f577b-007": "alreadyUsedBonuses",
  "d8d6d516-001": "alreadyUsedPromoCode",
  "d8d6d516-002": "alreadyAddedPromoCode",
  "d8d6d516-003": "promoCodeExpired",
  "d8d6d516-004": "promoCodeNotAvailable",
  "461e5e5e-023": "promoCodeNotAvailable",
};
