import React, { FC } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { UseFormSetError } from "react-hook-form";
import { IOTPFormData } from "../../../boundary/IOTPFormData";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import { getMessageBus, getProfileService } from "../../../diContainer/getDependencies";
import { ValidateOtp } from "../../components/ValidateOtp";

export const PhoneActivationPage: FC = () => {
  const profileService = getProfileService();
  const messageBus = getMessageBus();

  const data = useObservable(profileService.data$);

  const handleActivateProfile = (otp: string, setError: UseFormSetError<IOTPFormData>) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SendActivationCodeButtonClick",
      source: "PhoneActivationPage",
    });
    messageBus.send(analyticEvent);

    profileService.activateProfileByOTP(otp, setError);
  };

  const handleResendCode = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ResendActivationOtpButtonClick",
      source: "PhoneActivationPage",
    });
    messageBus.send(analyticEvent);

    profileService.resendActivationCode();
  };

  return (
    <ValidateOtp
      isLoading={data?.loading ?? false}
      phoneNumber={data?.profile?.phone ?? ""}
      onSendOtpClick={handleActivateProfile}
      onResendActivationCode={handleResendCode}
      canEditPhone={false}
    />
  );
};
