import { FC } from "react";
import timeIcon from "../../../icons/time.svg";
import { DemoatomicLayout } from "./DemoatomicLayout";
import { DemoatomicHomeButton } from "./DemoatomicHomeButton";

export const DemoatomicActionSuccess: FC = () => (
  <DemoatomicLayout
    icon={timeIcon}
    title="successTitle"
    className="actionResult"
  >
    <DemoatomicHomeButton />
  </DemoatomicLayout>
);
