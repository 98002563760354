import { decodeBase64 } from "@roketus/web-toolkit";
import {
  ActionsPaths,
  AnonymousActionsPaths,
} from "../adapters/router/actionsRoutes";
import { actionErrorCodes } from "../domain/specs/errorCodes";

const ERROR_HEADER = "failHeader";
const UNKNOWN_ERROR_TEXT = "unknownErrorText";
const TO_MAIN_TEXT = "toMainPage";
const CLOSE_TEXT = "close";

export const errorTextByActionType = {
  [ActionsPaths.Bonus]: "failBonusText",
  [ActionsPaths.Coupon]: "failCouponText",
  [ActionsPaths.Notification]: "failNotificationText",
  [ActionsPaths.Promo]: "failPromoText",
  [ActionsPaths.VodohraiPromo]: "failPromoText", // TODO: remove; HARDCODE for OnDe vodohrai promo
  [ActionsPaths.SensePromo]: "failPromoText", // TODO: remove; HARDCODE for OnDe sense promo
  [ActionsPaths.Survey]: "failBonusText",
  [AnonymousActionsPaths.AnonymousCoupon]: "failCouponText",
};

export const successTextByActionType = {
  [ActionsPaths.Bonus]: "successBonusText",
  [ActionsPaths.Coupon]: "successCouponText",
  [ActionsPaths.Notification]: "successNotificationText",
  [ActionsPaths.Promo]: "successPromoText",
  [ActionsPaths.VodohraiPromo]: "successPromoText", // TODO: remove; HARDCODE for OnDe vodohrai promo
  [ActionsPaths.SensePromo]: "successPromoText", // TODO: remove; HARDCODE for OnDe sense promo
  [ActionsPaths.Survey]: "successBonusText",
  [AnonymousActionsPaths.AnonymousCoupon]: "successCouponText",
};

const errorHeaderByTextKey = {
  alreadyUsedPromoCode: "warning",
  alreadyAddedPromoCode: "warning",
  promoCodeExpired: "sorryHeader",
};

export const getErrorContentByActionType = (
  actionType: ActionsPaths,
  errorCode?: string
) => {
  const textKey =
    errorCode || errorTextByActionType[actionType] || UNKNOWN_ERROR_TEXT;
  const titleKey =
    errorHeaderByTextKey[errorCode as keyof typeof errorHeaderByTextKey] ||
    ERROR_HEADER;
  const buttonTextKey = TO_MAIN_TEXT;

  return { titleKey, textKey, buttonTextKey };
};

export const getSuccessContentByActionType = (actionType: ActionsPaths) => {
  const textKey = successTextByActionType[actionType] || "";
  const titleKey = "successHeader";
  const buttonTextKey = TO_MAIN_TEXT;

  return { titleKey, textKey, buttonTextKey };
};

export const getActionUnknownErrorContent = () => {
  return {
    titleKey: ERROR_HEADER,
    textKey: UNKNOWN_ERROR_TEXT,
    buttonTextKey: CLOSE_TEXT,
  };
};

export const getActionErrorFromQuery = (base64Error: string | null) => {
  const titleKey = ERROR_HEADER;
  const buttonTextKey = TO_MAIN_TEXT;
  let textKey = UNKNOWN_ERROR_TEXT;

  if (!base64Error) return { textKey, titleKey, buttonTextKey };

  try {
    const errorString = decodeBase64(base64Error) as string;
    const [errorCode] = errorString.split(":");

    if (errorCode in actionErrorCodes)
      textKey = actionErrorCodes[errorCode as keyof typeof actionErrorCodes];
  } catch (e) {}

  return { textKey, titleKey, buttonTextKey };
};

export const getActionError = ({
  errorBase64,
  actionType,
  errorCode,
}: {
  errorBase64?: string | null;
  actionType?: ActionsPaths;
  errorCode?: string;
}): { textKey: string; titleKey: string; buttonTextKey: string } => {
  if (actionType) {
    return getErrorContentByActionType(actionType, errorCode);
  }

  if (errorBase64) {
    return getActionErrorFromQuery(errorBase64);
  }

  return getActionUnknownErrorContent();
};
