import { StateSubject } from "@roketus/web-toolkit";
import type { ICardService, ICardServiceData } from "../../boundary/ICardService";
import { getMessageBus, getSdk } from "../../diContainer/getDependencies";
import { EC_FAILED_TO_DOWNLOAD_CARD } from "../../domain/specs/errorCodes";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import { createDispatchError, downloadIOSCardAsBlob } from "../helpers";
import { buildAnalyticMessageEntity } from "../../domain/entities/analyticEntity";

const stateMachine = new StateSubject<ICardServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
});

const dispatchError = createDispatchError("cardService");

const downloadIOSCard = async () => {
  const startTime = performance.now();

  pushLogToFaro("Downloading iOS card");

  const messageBus = getMessageBus();
  const analyticEvent = buildAnalyticMessageEntity({
    action: "AppleWalletButtonClick",
    source: "AppleWalletButton",
  });
  messageBus.send(analyticEvent);

  stateMachine.setState({
    loading: true,
    isLoaded: false,
  });

  try {
    const sdk = getSdk();

    const data = (await sdk.card.getIOSCard().result) as unknown as Blob;

    downloadIOSCardAsBlob(data);

    stateMachine.setState({
      loading: false,
      isLoaded: true,
    });

    pushLogToFaro("Success: downloading iOS card", {
      duration: `${performance.now() - startTime}ms`,
    });
  } catch (e: any) {
    pushErrorToFaro(e, {
      message: "Error: failed to download iOS card",
      duration: `${performance.now() - startTime}ms`,
    });

    stateMachine.setState({
      loading: false,
      isLoaded: false,
      failed: true,
    });

    const message = "Failed To Download iOS Card";
    dispatchError({
      code: EC_FAILED_TO_DOWNLOAD_CARD,
      error: e,
      message,
    });
  }
};

const downloadAndroidCard = async () => {
  const startTime = performance.now();

  pushLogToFaro("Downloading Android card");

  const messageBus = getMessageBus();
  const analyticEvent = buildAnalyticMessageEntity({
    action: "AndroidWalletButtonClick",
    source: "AndroidWalletButton",
  });
  messageBus.send(analyticEvent);

  const sdk = getSdk();

  try {
    const { data } = await sdk.card.getAndroidCardDownloadLink().result;
    window.open(data.google_pass_url, "_self");

    pushLogToFaro("Success: downloading Android card", {
      duration: `${performance.now() - startTime}ms`,
    });

    stateMachine.setState({
      isLoaded: true,
      failed: false,
    });
  } catch (e: any) {
    pushErrorToFaro(e, {
      message: "Error: failed to download Android card",
      duration: `${performance.now() - startTime}ms`,
    });

    stateMachine.setState({
      isLoaded: false,
      failed: true,
    });

    const message = "Failed to download android card";
    dispatchError({
      code: EC_FAILED_TO_DOWNLOAD_CARD,
      error: e,
      message,
    });
  }
};

export const cardService: ICardService = {
  data$: stateMachine.state$,
  downloadAndroidCard,
  downloadIOSCard,
};
