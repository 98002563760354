import { isEmpty } from "lodash";
import {
  IPassDTO,
  IPassInfo,
  IBarcodeInfo,
  IBarcodeType,
  IBarcodeDataDTO,
} from "../../boundary/IProfileService";

const getBarcodeType = (barcodeData?: IBarcodeDataDTO): IBarcodeType => {
  if (isEmpty(barcodeData) || !barcodeData.UseBarcode) return "qrcode";

  switch (barcodeData.BarcodeFormatId) {
    case 1:
      return "pdf417";
    case 3:
      return "azteccode";
    case 4:
      return "code128";
    default:
      return "qrcode";
  }
};

const getBarcodeText = (
  cardNumber: string,
  barcodeData?: IBarcodeDataDTO
): string => {
  const linkToPos = `${process.env.REACT_APP_LOYALTY_GUI_URL}pos/${cardNumber}`;

  if (isEmpty(barcodeData) || !barcodeData.UseBarcode) return linkToPos;

  return barcodeData.BarcodeMessage || linkToPos;
};

export const toPassAndBarcodeEntities = (
  passData: IPassDTO | null,
  cardNumber: string
): [IPassInfo | null, IBarcodeInfo] => {
  const passInfo: IPassInfo | null = passData
    ? {
        organizationName: passData?.OrganizationName,
        description: passData?.Description,
      }
    : null;
  const barcodeInfo = {
    type: getBarcodeType(passData?.PassBarcode),
    text: getBarcodeText(cardNumber, passData?.PassBarcode),
    alterText: passData?.PassBarcode.BarcodeAltText || cardNumber,
  };

  return [passInfo, barcodeInfo];
};
