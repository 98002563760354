import { useTranslation } from "react-i18next";
import { Box, Paper, Typography } from "@mui/material";
import { getCardService } from "../../../diContainer/getDependencies";
import { AndroidWalletButton } from "./AndroidWalletButton/AndroidWalletButton";
import { AppleWalletButton } from "./AppleWalletButton/AppleWalletButton";

const DownloadCardBox = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const cardService = getCardService();

  return (
    <Paper sx={{ p: 3, width: '100%' }}>
      <Typography variant="h5" align="center">
        {t("downloadCardLabel")}
      </Typography>
      <Box sx={{ pt: 2 }}>
        <AndroidWalletButton onClick={cardService.downloadAndroidCard} />
      </Box>
      <Box sx={{ pt: 2 }} display="flex" justifyContent="center">
        <AppleWalletButton onClick={cardService.downloadIOSCard} />
      </Box>
    </Paper>
  );
};

export default DownloadCardBox;
