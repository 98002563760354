import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useIsAuthenticated } from "../../../adapters/hooks/useIsAuthenticated";
import { DescriptionLogo } from "./DescriptionLogo";

type IProps = {
  isAnonymous?: boolean,
};

export const CouponActionDescription: React.FC<IProps> = ({
  isAnonymous = false,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action",
  });

  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Typography variant="body1" align="center" gutterBottom>
        {t("thanksForScanning")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("thanksForChoosing")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("valuableCustomer")}
      </Typography>
      <DescriptionLogo type="loyalty" />
      <Typography variant="h5" align="center" gutterBottom>
        {t("reward")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("coupon.reward")}
      </Typography>
      {!isAnonymous && !isAuthenticated && (
        <Typography variant="body1" align="center" gutterBottom>
          {t("coupon.leavePhone")}
        </Typography>
      )}
    </>
  );
};
