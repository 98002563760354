import {
  EC_FAILED_FETCH_CONFIG_DATA,
  EC_INVALID_ISSUER_SYS_NAME,
  EC_NO_CARD,
} from "../../domain/specs/errorCodes";
import { Ii18nScheme } from "./en";

const lt: Ii18nScheme = {
  translation: {
    forms: {
      default: {
        required: "* Privalomas laukelis",
      },
    },
    common: {
      copy: "Kopijuoti",
      copiedMessage: "Nuoroda nukopijuota!",
      phoneInput: {
        placeholder: "Įveskite telefono numerį",
      },
      errors: {
        "user not found": "Vartotojas nerastas",
        "wrong phone code": "Neteisingas telefono kodas",
        isRequired: "{{label}} yra privaloma",
        unknownTitle: "Klaida",
        unknownText: "Ups, kažkas nepavyko",
        firstNameError: "Netinkamas vardas",
        lastNameError: "Netinkama pavardė",
        minLength: "Įvesties vertė yra per trumpa",
        phone: {
          required: "Negaliojantis telefono numeris",
          "phone belong another user": "Telefono numeris jau užregistruotas",
          unknownText: "Oops, kažkas nepavyko, pabandykite dar kartą vėliau",
          "user not found": "Vartotojas nerastas",
          "incorrect phone": "Neteisingas telefono numeris",
        },
        otp: {
          required: "reikalingas otp",
          pattern: "leidžiama naudoti tik skaičius",
          minLength: "Min. ilgis 6 simboliai",
          maxLength: "Max. ilgis 6 simboliai",
          "wrong phone code": "Neteisingas telefono kodas",
          unknownText: "Oops, kažkas nepavyko, pabandykite dar kartą vėliau",
        },
      },
      date: {
        month: {
          January: "Sausis",
          February: "Vasaris",
          March: "Kovas",
          April: "Balandis",
          May: "Gegužė",
          June: "Birželis",
          July: "Liepa",
          August: "Rugpjūtis",
          September: "Rugsėjis",
          October: "Spalis",
          November: "Lapkritis",
          December: "Gruodis",
        },
      },
      showAll: "Rodyti visus",
      hide: "Paslėpti",
    },
    inAppBrowserWarning: {
      warningTitle: "Open in Browser",
      copyMessage:
        "Please copy this link and open it in the browser to fully enjoy the application.",
      orMessage:
        "Or use long press on the link in the message <span>-> 'Open in...'</span>",
    },
    signIn: {
      index: {
        header: "Prisijungti",
        text: "Pasirinkite savo šalies kodą ir įveskite telefono numerį",
        buttonLabel: "Užsiregistruoti",
        confirmationLabel: "Siųsti patvirtinimo kodą",
        company: "Įmonė",
      },
      validateOtp: {
        header: "Įveskite patvirtinimo kodą",
        text: "Įveskite 6 skaitmenų kodą, kurį atsiuntėme į xxx xxxx {{phoneNumberSlice}}.",
        changePhoneNumber: "Redaguoti telefono numerį",
        noCode: "Negavote patvirtinimo kodo?",
        resendCode: "Siųsti kodą iš naujo",
        waitResendStart: "Laukite ",
        waitResendEnd: "sekundžių, kad bandytumėte dar kartą",
        buttonLabel: "Patvirtinti kodą",
        notReceivingOtp: "Negavote OTP?",
      },
    },
    signUp: {
      setPhone: {
        header: "Užsiregistruoti",
        text: "Atsiųsime jums 6 skaitmenų patvirtinimo kodą",
        buttonLabel: "Siųsti kodą {{countdownTitle}}",
        termsAndConditionsLabel:
          "Sutinku su <lt>SĄLYGOMIS</lt> IR <lc>PRIVATUMO POLITIKA</lc>",
        hasAccount: "Jau turite paskyrą?",
        company: "Įmonė",
      },
      phoneActivation: {
        title: "Prisijungėte!",
        header:
          "Sveiki atvykę į {{{issuerTitle}} lojalumo programą ir visus jos privalumus.<br />Džiaukitės įvairiais uždarbiais, pasiūlymais ir dar daugiau.",
        bonuses: "NETRUKUS GAUSITE SAVO PREMIJAS!",
        moreBonuses: "Gaukite daugiau premijų",
        bonusesProgram: "Premijų programa",
        complete: "Užbaikite aktyvavimą, kad pridėtumėte daugiau premijų",
        buttonLabel: "Tęsti",
      },
      setProfile: {
        firstNameError: "Netinkamas vardas",
        firstNameLabel: "Vardas",
        lastNameError: "Netinkama pavardė",
        lastNameLabel: "Pavardė",
        continueButton: "Tęsti",
        skipButton: "Praleisti",
        header: "Įveskite savo vardą",
        cardPreview: "Kortelės peržiūra",
      },
      setBirthday: {
        header: "Įveskite savo gimtadienį",
        continueButton: "Tęsti",
        dayLabel: "Mėnesio diena",
        monthLabel: "Mėnuo",
        yearLabel: "Metai",
      },
      step: {
        Basic: "",
        Profile: "Nustatyti profilį",
        SetPhone: "",
        PhoneActivation: "",
        PhoneActivationSuccess: "",
        SetEmail: "Patvirtinkite savo el. paštą",
        EmailActivation: "",
        SetBirthday: "Nustatyti gimtadienį",
        Completed: "Profilis",
      },
    },
    profile: {
      title: "Jūsų paskyra",
      company: "Įmonė: {{name}}",
      organizationName: "Organizacijos pavadinimas:",
      description: "Aprašymas:",
      mainText:
        "Tai jūsų {{issuerTitle}} lojalumo programos paskyra, kurioje galite pasitikrinti likutį, pakviesti draugus ir tvarkyti asmeninę informaciją.",
      dateLabel: "Galioja iki: ",
      downloadCardForIos: "Atsisiųsti iOS kortelę",
      downloadCardForAndroid: "Atsisiųsti Android kortelę",
      balanceLabel: "Likutis",
      discountLabel: "Pirkimo premija",
      cardNumberLabel: "Kortelės numeris",
      downloadCardLabel: "Atsisiųsti skaitmeninę kortelę",
      logout: "Atsijungti",
      logoutConfirm: "Ar tikrai norite išeiti?",
      yes: "Taip",
      no: "Ne",
      promoCodes: "Promo kodai",
      notActivatedWarning:
        "Jei norite pasinaudoti premijomis, patvirtinkite savo telefono numerį.",
      activate: "Aktyvuoti",
    },
    changeProfile: {
      title: "Atnaujinti asmeninę informaciją",
      buttonLabel: "Atnaujinti",
      firstNameLabel: "Vardas",
      lastNameLabel: "Pavardė",
    },
    changePhoneNumber: {
      title: "Atnaujinkite savo mobiliojo telefono numerį",
      buttonLabel: "Atnaujinti",
      updateButtonLabel: "Atnaujinti {{time}}",
    },
    changeEmail: {
      title: "Atnaujinti el. paštą",
      buttonLabel: "Atnaujinti",
    },
    emailVerification: {
      send: "Siųsti patvirtinimą",
      skip: "Praleisti",
      title: "Patvirtinkite el. pašto adresą!",
      message: "Įveskite galiojantį el. pašto adresą.",
      email: "El. paštas",
    },
    referralProgram: {
      title: "Pakvieskite draugą",
      description: "Nukopijuokite saitą ir nusiųskite draugui",
    },
    action: {
      thanksForScanning: "Ačiū, kad nuskaitėte QR kodą!",
      thanksForChoosing:
        "Nuoširdžiai dėkojame, kad nusprendėte pasinaudoti mūsų specialiu pasiūlymu!",
      valuableCustomer:
        "Jūs esate mūsų svarbiausi klientai, o jūsų pasirinkimas daro mus dar geresnius",
      reward: "Atlygis",
      bonus: {
        reward:
          "Turite galimybę gauti 100 premijų, kurias galėsite išleisti mūsų parduotuvėje",
        leavePhone: "Norėdami gauti premijas, palikite savo telefono numerį",
      },
      coupon: {
        reward:
          "Turite galimybę gauti kuponą, kurį galėsite panaudoti mūsų parduotuvėje",
        leavePhone: "Palikite savo telefono numerį ir jį gaukite",
      },
      promo: {
        reward:
          "Turite galimybę gauti reklaminį kodą, kurį galėsite panaudoti mūsų parduotuvėje",
        leavePhone: "Palikite savo telefono numerį ir jį gaukite",
      },
      notification: {
        reward: "Turite galimybę gauti unikalų pasiūlymą iš mūsų parduotuvės",
        leavePhone: "Palikite savo telefono numerį ir jį gaukite",
      },
      survey: {
        reward:
          "Užpildę apklausą turite galimybę gauti 100 premijų, kurias galėsite išleisti mūsų parduotuvėje",
        leavePhone: "Palikite savo telefono numerį ir jas gaukite",
      },
      surveyForm: {
        question: "Ar jums patinka mūsų parduotuvė?",
        yes: "Taip",
        no: "Ne",
        next: "Toliau",
      },
      dispatchResult: {
        successHeader: "Sveikiname!",
        failHeader: "Kažkas nepavyko",
        sorryHeader: "Atsiprašome",
        warning: "ĮSPĖJIMAS!",
        successBonusText:
          "Jums bus skirti premijiniai taškai<br />*premijiniai taškai bus aktyvuoti pagal LP taisykles",
        successCouponText: "Gavote pasiūlymą",
        successPromoText: "Gausite promo kodą",
        successNotificationText: "Gausite pasiūlymą",
        failBonusText: "Premijos nebuvo skirtos",
        failCouponText: "Nepavyko išsiųsti kupono",
        failNotificationText: "Nepavyko išsiųsti pranešimo",
        failPromoText: "Nepavyko išsiųsti promo kodo",
        unknownErrorText: "Pasiūlymas jau aktyvuotas",
        promoCodeNotFound: "Reklamos kredito kodo rasti nepavyko",
        alreadyUsedBonuses: "Premijos jau panaudotos",
        alreadyAddedPromoCode: "Reklaminis pasiūlymas jau pridėtas",
        alreadyUsedPromoCode: "Dovanos akcijos kodas jau panaudotas",
        promoCodeExpired: "Reklaminis pasiūlymas nebepasiekiamas",
        promoCodeNotAvailable: "Reklaminis pasiūlymas negalimas",
        toMainPage: "Eiti į pagrindinį puslapį",
        close: "Uždaryti",
        pleaseWait: "Prašome palaukti",
      },
      demoatomic: {
        bonusTitle: "<span>Thank</span> you for scanning the QR code!",
        surveyTitle:
          "Please answer <span>one question</span>. Did you enjoy being in our store?",
        qrTitle: "<span>Only today!</span> Exclusive offer",
        couponTitle:
          "<span>Only today!</span> Join now and get exclusive discounts and bonuses!",
        successTitle: "<span>Thanks</span> for your time and cooperation",
        errorTitle: "<span>Oops!</span> Something went wrong",
        sorryTitle: "<span>Sorry!</span><br/>See you soon!",
        bonusText:
          "We sincerely thank you for choosing to take advantage of <b>our special offer</b>! You are our most valuable customer and your choice makes us even better. You have a chance to get the benefits of <b>our loyalty program</b>",
        phoneLabel: "Please leave your phone number to use this opportunity",
        surveyQuestion: "Did you enjoy being in our store?",
        surveyYes: "I really liked it",
        surveyNo: "Could be better",
        homeButton: "Home",
        close: "Cancel",
        confirm: "Confirm",
      },
    },
    landing: {
      title: "Informacija apie produktą",
      text: "Mūsų gaminiai yra geriausi iš visų kitų gaminių",
    },
    welcomePage: {
      ok: "OK",
      cancel: "Atšaukti",
    },
    infoPage: {
      message: "Atsiprašome.<br />Iki pasimatymo!",
      notFound: "Atsiprašome.<br />Puslapis nerastas",
    },
    footer: {
      help: "Pagalba",
    },
    errorPage: {
      [EC_FAILED_FETCH_CONFIG_DATA]: "Failed to fetch config",
      [EC_INVALID_ISSUER_SYS_NAME]:
        "Kažkas negerai su nuoroda, prašome nuskaityti dar kartą",
      [EC_NO_CARD]: "Lojalumo kortelė nerasta",
      unknownTitle: "Oops!",
      unknownText:
        "Lojalumo programa, kurią bandote pasiekti, dar neprasidėjo arba URL yra neteisingas. Sekite naujienas!",
      actionText: "Eiti į pagrindinį puslapį",
    },
  },
};

export default lt;
