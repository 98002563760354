import React, { FC, useMemo } from "react";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { useObservable } from "@roketus/web-toolkit";
import { getAppStylingService } from "../diContainer/getDependencies";

export const ThemeProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const appStylingService = getAppStylingService();
  const data = useObservable(appStylingService.state);
  const theme = useMemo(() => {
    return data ?? appStylingService.createThemeByConfig({});
  }, [data, appStylingService]);

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};
