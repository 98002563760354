import { templateIdsByCampaign } from "../domain/specs/templateIdsByCampaign";

export const getTemplateIdByCampaign = (campaign: string): number | null => {
  if (campaign in templateIdsByCampaign) {
    return templateIdsByCampaign[
      campaign as keyof typeof templateIdsByCampaign
    ];
  }
  return null;
};

export const getActionCampaignParams = (
  campaign: string
): { templateId?: number } => {
	const templateId = getTemplateIdByCampaign(campaign);
	return templateId ? { templateId } : {};
};
