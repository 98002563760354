import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as UAAppleWalletButtonSvg } from "./UA_Add_to_Apple_Wallet_RGB_102121.svg";
import { ReactComponent as USAppleWalletButtonSvg } from "./US_Add_to_Apple_Wallet_RGB_101421.svg";

export const AppleWalletButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { i18n } = useTranslation("translation");

  const AppleWalletButtonSvg =
    i18n.language === "uk" ? UAAppleWalletButtonSvg : USAppleWalletButtonSvg;

  return (
    <AppleWalletButtonSvg
      style={{
        width: "98%",
        height: "auto",
        pointerEvents: "initial",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
};
