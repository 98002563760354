import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "./Link";
import { Dispatch, SetStateAction } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { getIssuerConfigService } from "../../diContainer/getDependencies";

interface ILoyaltyPolicy {
  termsChecked: boolean;
  setTermsChecked: Dispatch<SetStateAction<boolean>>;
}

export const LoyaltyPolicy: React.FC<ILoyaltyPolicy> = ({
  termsChecked,
  setTermsChecked,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "signUp.setPhone" });

  const issuerConfigService = getIssuerConfigService();
  const data = useObservable(issuerConfigService.data$);

  const privacyPolicyLink =
    data?.config.language === "uk"
      ? "/Privacy_policy_060824_Roketus_ukr.pdf"
      : "/Privacy_policy_060824_Roketus_eng.pdf";

  const termsAndConditionsLink =
    data?.config.language === "uk"
      ? "/Terms_and_Conditions_Loyalty_System_UKR_230724.pdf"
      : "/Terms_and_Conditions_Loyalty_System_ENG_230724.pdf";

  return (
    <FormControlLabel
      control={
        <Checkbox
          value="allowExtraEmails"
          color="primary"
          checked={termsChecked}
          onChange={(e) => setTermsChecked(e.target.checked)}
          sx={{
            flex: "0 0 auto",
          }}
        />
      }
      sx={{ alignItems: "flex-start" }}
      label={
        <Typography sx={{ pt: 1 }}>
          <Trans
            i18nKey="termsAndConditionsLabel" // optional -> fallbacks to defaults if not provided
            t={t}
            values={{ issuerTitle: "titles.issuerTitle" }}
            components={{
              lc: (
                <Link
                  to={privacyPolicyLink}
                  target="_blank"
                  className="c-checkbox__link"
                />
              ),
              lt: (
                <Link
                  to={termsAndConditionsLink}
                  target="_blank"
                  className="c-checkbox__link"
                />
              ),
            }}
          />
        </Typography>
      }
    />
  );
};
