import { StateSubject } from "@roketus/web-toolkit";
import { get, isEmpty, isString } from "lodash";
import {
  ISignInService,
  ISignInServiceData,
} from "../../boundary/ISignInService";
import {
  EC_LOGIN_INVALID_PHONE,
  EC_LOGIN_EMPTY_PHONE,
  EC_LOGIN_EMPTY_JWT,
  CustomCodeError,
} from "../../domain/specs/errorCodes";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import {
  getSdk,
  getActionService,
  getAuthService,
  getIssuerConfigService,
  getProfileService,
  getRouterService,
  getSignupService,
} from "../../diContainer/getDependencies";

export const stateMachine = new StateSubject<ISignInServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  errorMessage: "",
});

const setPhoneNumberOTP: ISignInService["setPhoneNumberOTP"] = (
  phoneNumber
) => {
  stateMachine.setState({
    phoneNumber: phoneNumber,
  });
};

export const logIn = async (phoneNumber: string) => {
  pushLogToFaro("Log in: setting phone");

  stateMachine.setState({
    loading: true,
    isLoaded: false,
    failed: false,
    errorMessage: "",
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  try {
    const phoneNumberFiltered = phoneNumber.replace(/\s+/g, "");
    await sdk.auth.loginByPhone(phoneNumberFiltered).result;

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: false,
      phoneNumber,
    });

    pushLogToFaro("Success: logged in by phone, waiting OTP");

    routerService.navigateToIssuerPath("/signin/validate-otp");
  } catch (error) {
    const issuerConfigService = getIssuerConfigService();
    const signupService = getSignupService();

    pushErrorToFaro(error, {
      action: "logIn",
      issuer: String(issuerConfigService?.getIssuer()),
      message: "Error: failed to log in by phone",
    });

    signupService.setPhoneNumber(phoneNumber);

    const errorText = get(error, "response.data.data[0].title", "unknownText");

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
      errorMessage: errorText,
    });

    return new Error(EC_LOGIN_INVALID_PHONE);
  }
};

export const logInValidateOTP: ISignInService["logInValidateOTP"] = async (
  otpCode,
  setError
) => {
  pushLogToFaro("Log in: validating OTP");

  stateMachine.setState({
    loading: true,
    isLoaded: false,
    failed: false,
  });

  const sdk = getSdk();
  const routerService = getRouterService();
  const actionService = getActionService();
  const authService = getAuthService();

  try {
    const { phoneNumber } = stateMachine.state$.value;

    if (!isString(phoneNumber) || isEmpty(phoneNumber)) {
      throw new CustomCodeError(EC_LOGIN_EMPTY_PHONE);
    }

    const { data } = await sdk.auth.loginByPhoneCheckCode(
      phoneNumber.replace(/\s+/g, ""),
      parseInt(otpCode, 10)
    ).result;

    if (!isString(data.jwt) || isEmpty(data.jwt)) {
      throw new CustomCodeError(EC_LOGIN_EMPTY_JWT);
    }

    authService.setTokens(data.jwt, data.refresh);
    authService.setIsAuthenticated();

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: false,
    });

    const { actionType } = actionService.data$.value;

    pushLogToFaro("Success: otp is valid, logged in", { actionType });

    routerService.navigateToInitialPage();
  } catch (error) {
    pushErrorToFaro(error, {
      action: "logInValidateOTP",
      message: "Error: failed to validate OTP on log in",
    });

    // if no phone - redirect to signin
    if (
      error instanceof CustomCodeError &&
      error.message === EC_LOGIN_EMPTY_PHONE
    ) {
      stateMachine.setState({
        loading: false,
        isLoaded: false,
        failed: false,
      });

      routerService.navigateToIssuerPath("/signin");

      return;
    }

    const errorText = get(error, "response.data.data[0].title", "unknownText");

    setError("otp", { message: errorText, type: errorText });
    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });
  }
};

export const logOut = () => {
  const authService = getAuthService();
  const profileService = getProfileService();
  const routerService = getRouterService();

  pushLogToFaro("User logged out");

  authService.removeTokens();
  profileService.clearState();

  routerService.navigateToIssuerPath("/signin");
};

export function init(): ISignInService {
  return {
    data$: stateMachine.state$,
    logIn,
    logInValidateOTP,
    setPhoneNumberOTP,
    logOut,
  };
}
