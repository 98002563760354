import { FC } from "react";
import { Box } from "@mui/material";
import { createRenderBarcode } from "./barcodeHelper";
import { IBarcodeType } from "../../../boundary/IProfileService";

interface IBarcodeProps {
  type: IBarcodeType;
  text: string;
}

export const Barcode: FC<IBarcodeProps> = ({ text, type }) => {
  const renderBarcode = createRenderBarcode({ text, type });

  return (
    <Box
      sx={{
        backgroundColor: "white",
        lineHeight: 0,
      }}
    >
      <canvas style={{ width: "100%", maxWidth: 700 }} ref={renderBarcode} />
    </Box>
  );
};
