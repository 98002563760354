export const Arrow = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 1C11.5 0.723858 11.2761 0.5 11 0.5L6.5 0.5C6.22386 0.5 6 0.723858 6 1C6 1.27614 6.22386 1.5 6.5 1.5L10.5 1.5L10.5 5.5C10.5 5.77614 10.7239 6 11 6C11.2761 6 11.5 5.77614 11.5 5.5L11.5 1ZM1.35355 11.3536L11.3536 1.35355L10.6464 0.646447L0.646447 10.6464L1.35355 11.3536Z"
      fill="white"
    />
  </svg>
);
