import { filter } from "rxjs";
import UAParser from "ua-parser-js";
import { sdk } from "@roketus/loyalty-end-user-js-sdk";
import { isAnalyticEvent } from "../../domain/entities/analyticEntity";
import {
  getIssuerConfigService,
  getMessageBus,
  getProfileService,
} from "../../diContainer/getDependencies";

export const initAnalyticsService = () => {
  const messageBus = getMessageBus();
  const profileService = getProfileService();
  const issuerConfigService = getIssuerConfigService();

  messageBus.stream$.pipe(filter(isAnalyticEvent)).subscribe((message) => {
    const ua = UAParser();

    const { profile } = profileService?.data$?.value ?? {};

    const userId = profile?.id || "userId";
    const issuerId = issuerConfigService.getIssuer() ?? "id";

    sdk.analytics.createEvent({
      userId,
      action: message.data.action,
      metadata: {
        issuerId,
        OSType: ua.os.name,
        OSVersion: ua.os.version,
        source: message.source,
        ...(message.data.meta || {})
      }
    });
  });
};
