import { FC, ReactElement, useRef, useCallback, useEffect } from "react";
import { Popover } from "@mui/material";

interface IProps {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
  children: ReactElement;
}

const PopoverAutoClosing: FC<IProps> = ({ anchorEl, onClose, children }) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleClose = useCallback(() => {
    onClose();

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, [onClose]);

  useEffect(() => {
    if (anchorEl) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        handleClose();
      }, 2000);
    }
  }, [anchorEl, handleClose]);

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Popover
      id="popover"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
    >
      {children}
    </Popover>
  );
};

export default PopoverAutoClosing;
