import {
  getActionService,
  getIssuerConfigService,
} from "../../../diContainer/getDependencies";
import { IssuerToken } from "../../../domain/specs/issuerToken";
import { SurveyForm } from "../../components/SurveyForm";
import { DemoatomicSurveyAction } from "../../components/actionComponents/demoatomic/DemoatomicSurveyAction";

export const SurveyActionForm: React.FC = () => {
  const issuerConfigService = getIssuerConfigService();
  const actionService = getActionService();

  const issuerSysName = issuerConfigService.getIssuer();

  if (issuerSysName === IssuerToken.Demoatomic) {
    return (
      <DemoatomicSurveyAction submitForm={actionService.submitSurveyForm} />
    );
  }

  return <SurveyForm submitForm={actionService.submitSurveyForm} />;
};
