import { FC } from "react";
import { useLocation } from "react-router-dom";
import {
  ANONYMOUS_ACTIONS_PATHS,
  AnonymousActionsPaths,
} from "../../../adapters/router/actionsRoutes";
import { IssuerToken } from "../../../domain/specs/issuerToken";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import {
  getActionService,
  getIssuerConfigService,
  getMessageBus,
  getRouterService,
} from "../../../diContainer/getDependencies";
import { StandardWelcomePage } from "../../components/actionComponents/StandardWelcomePage";
import { DemoatomicWelcomePage } from "../../components/actionComponents/demoatomic/DemoatomicWelcomePage";
import { DemoatomicCouponAction } from "../../components/actionComponents/demoatomic/DemoatomicCouponAction";

type TPhoneFormData = {
  phone: string;
};

export const WelcomePage: FC = () => {
  const routerService = getRouterService();
  const issuerConfigService = getIssuerConfigService();
  const actionService = getActionService();
  const messageBus = getMessageBus();

  const location = useLocation();
  const actionType = location.pathname.split("/").at(-1);
  const isAnonymous = ANONYMOUS_ACTIONS_PATHS.includes(
    actionType as AnonymousActionsPaths
  );
  const issuerSysName = issuerConfigService.getIssuer();

  const handleSubmit = (formData?: TPhoneFormData) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ApproveActionClick",
      source: "WelcomePage",
    });
    messageBus.send(analyticEvent);

    if (isAnonymous) {
      actionService.approvePublicAction();
    } else {
      actionService.approveAction(formData!.phone);
    }
  };

  const handleCancel = () => {
    routerService.navigateToIssuerPath("/info");
  };

  if (issuerSysName === IssuerToken.Demoatomic) {
    if (actionType === AnonymousActionsPaths.AnonymousCoupon) {
      return (
        <DemoatomicCouponAction
          onSubmit={handleSubmit}
          onCancel={handleCancel}
        />
      );
    }

    return (
      <DemoatomicWelcomePage
        isAnonymous={isAnonymous}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
      />
    );
  }

  return (
    <StandardWelcomePage
      isAnonymous={isAnonymous}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
    />
  );
};
