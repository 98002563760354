import { useForm } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import { ISurveyFormPayload } from "../../../boundary/forms/IFreeSurveyFormData";
import { getActionService } from "../../../diContainer/getDependencies";
import { Banner } from "./Banner/Banner";
import { Info } from "./Info/Info";
import { Radio } from "./Radio/Radio";
import { Button } from "./Button/Button";
import { Wrapper } from "./Wrapper/Wrapper";

interface SurveyFormProps {
  submitForm: (data: ISurveyFormPayload) => void;
}

interface ISurveyFormData {
  survey: string;
}

export const SurveyRetailate: React.FC<SurveyFormProps> = ({ submitForm }) => {
  const { handleSubmit, register, watch } = useForm<ISurveyFormData>();

  const actionService = getActionService();

  const actionData = useObservable(actionService.data$);

  const questionDescription =
    "Чи подобається Вам асортимент TM «Королівській Смак» в магазині, де Ви придбали наш товар?";

  const onSubmit = ({ survey }: ISurveyFormData) => {
    submitForm({
      surveyDescription: "kingsmak, april2024, anonymous survey",
      surveyData: [
        {
          questionDescription,
          questionAnswers: [
            {
              name: "yes",
              value: survey === "true",
            },
            {
              name: "no",
              value: survey !== "true",
            },
          ],
        },
      ],
    });
  };

  const surveyValue = watch("survey");

  return (
    <Wrapper isSurvey>
      <Banner />
      <Info
        title="Допоможіть нам стати краще та надайте, будь ласка, відповідь на запитання"
        text={questionDescription}
      />

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Radio
          title="Так"
          variant="Yes"
          register={register("survey")}
          value="true"
          ref={null}
        />
        <Radio
          title="Ні"
          variant="No"
          register={register("survey")}
          value="false"
          ref={null}
        />
        <Button
          title="Далі"
          type="submit"
          disabled={actionData?.loading || !surveyValue}
          loading={!!actionData?.loading}
        />
      </form>
    </Wrapper>
  );
};
