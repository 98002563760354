import { isUndefined } from "lodash";
import { createTheme, Theme, ThemeOptions } from "@mui/material";
import { StateSubject } from "@roketus/web-toolkit";
import type {
  IIssuerConfigData,
  IIssuerConfigService,
} from "../../boundary/IIssuerConfigService";
import type { IAppStylingService } from "../../boundary/IAppStylingService";
import { IssuerParams } from "../../domain/specs/IssuerParams";
import {
  getBgColorForApp,
  getPrimaryColorForApp,
  setMetaThemeColor,
} from "../helpers";
import { isDarkThemeInBrowser, isSamsungBrowser } from "../../utils/utils";
import {
  CONTRAST_DARK_BORDER_COLOR,
  DEFAULT_DARK_BORDER_COLOR,
  ThemeMode,
  getBorderColor,
} from "../../domain/specs/theme";

const overrideMuiStyles = (
  themePayload: ThemeOptions,
  config: Partial<Record<IssuerParams, string>>,
  isDarkTheme: boolean
) => {
  if (isDarkTheme) {
    const borderColor = getBorderColor(
      config.bodyBgColor,
      DEFAULT_DARK_BORDER_COLOR,
      CONTRAST_DARK_BORDER_COLOR
    );

    themePayload.components = {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor,
            },
          },
        },
      },
    };
  }
};

const createThemeByConfig = (
  config: Partial<Record<IssuerParams, string>>
): Theme => {
  const themePayload: ThemeOptions = { palette: {} };
  const isDarkTheme = isDarkThemeInBrowser() || isSamsungBrowser();

  if (isUndefined(themePayload.palette)) return createTheme(themePayload);

  themePayload.palette.mode = isDarkTheme ? ThemeMode.DARK : ThemeMode.LIGHT;

  const primaryColor = getPrimaryColorForApp(config, isDarkTheme);
  themePayload.palette.primary = {
    main: primaryColor,
  };
  setMetaThemeColor(primaryColor);

  themePayload.palette.background = {
    default: getBgColorForApp(config, isDarkTheme),
  };

  overrideMuiStyles(themePayload, config, isDarkTheme);

  return createTheme(themePayload);
};
const stateMachine = new StateSubject<Theme>(createThemeByConfig({}));

export const init = (
  issuerConfigService: IIssuerConfigService
): IAppStylingService => {
  issuerConfigService.data$.subscribe((data: IIssuerConfigData) => {
    if (data.failed) {
      return;
    }

    const theme = createThemeByConfig(data.config);
    stateMachine.setFullState(theme);
  });

  return { state: stateMachine.state$, createThemeByConfig };
};
