import { IProfileInfoResponseAttributes } from "@roketus/loyalty-end-user-js-sdk";
import { isEmpty, isString } from "lodash";

export const isInvalidIssuerSysName = (
  issuerSysName?: string | null
): boolean => {
  return (
    !issuerSysName ||
    !isString(issuerSysName) ||
    isEmpty(issuerSysName) ||
    issuerSysName === "null" ||
    issuerSysName === "undefined"
  );
};

export const isRegistrationCompleted = (response: any): boolean => {
  return response?.activation?.[0]?.completed;
};

export const isOtpStep = (response: any): boolean => {
  return ["phoneActivation", "terms"].includes(
    response?.activation?.[0]?.nextStep
  );
};

export const getNextRegStep = (response: any): string => {
  return response?.activation?.[0]?.nextStep || "";
};

export const getNextRegStepPath = (
  nextStep: string = "",
  isRedirectedFromError?: boolean
): string => {
  switch (nextStep) {
    case "completed":
      return "/";
    case "profile":
      return "/signup/profile";
    case "phoneActivation":
    case "terms":
      return isRedirectedFromError ? "/signup" : "/signup/validate-otp";
    default:
      return "/signup";
  }
};

export const checkIfProfileActivated = (
  profile?: IProfileInfoResponseAttributes
): boolean => {
  return (
    !!profile && profile.activation[0].completed &&
    (profile.emailVerified || profile.phoneVerified)
  );
};
