import { IGoogleAnalyticsService } from "../../boundary/IAnalyticsService";

const sendGoogleAnalyticsEvent = (
  eventName: string,
  eventParameter: string,
  eventValue: string
) => {
  const gtag = window.gtag;
  gtag("event", eventName, {
    [eventParameter]: eventValue,
  });
};

export const googleAnalyticsService: IGoogleAnalyticsService = {
  sendGoogleAnalyticsEvent,
};
