import { useTranslation } from "react-i18next";
import { LayoutHeadless } from "../components/layouts/LayoutHeadless";
import { Box, Typography } from "@mui/material";
import CelebrationIcon from "@mui/icons-material/Celebration";

export const LandingPage: React.FC = () => {
  const { t } = useTranslation("translation");

  return (
    <LayoutHeadless>
      <Typography variant="body1" align="center" gutterBottom>
        {t("action.thanksForScanning")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("action.thanksForChoosing")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("action.valuableCustomer")}
      </Typography>
      <Box>
        <CelebrationIcon fontSize="large" />
      </Box>
      <Typography variant="h5" align="center" sx={{ m: "15px 0" }}>
        {t("landing.title")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("landing.text")}
      </Typography>
    </LayoutHeadless>
  );
};
