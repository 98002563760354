import React, { FC } from "react";
import {
  AppBar,
  Box,
  Container,
  Toolbar,
  Typography,
} from "@mui/material";
import { Footer } from "../Footer";
import LanguageMenu from "../LanguageMenu";
import LogOut from "../LogOut";

interface Props {
  heading?: string;
  children?: React.ReactNode;
}

export const ProfileLayout: FC<Props> = ({ children, heading }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <AppBar component="nav">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {heading}
          </Typography>
          <Box display='flex' gap={1}>
            <LanguageMenu isDropdown />
            <LogOut />
          </Box>
        </Toolbar>
      </AppBar>
      <Container>
        <Box sx={{ my: 2, marginTop: 8 }}>{children}</Box>
      </Container>
      <Footer />
    </Box>
  );
};
