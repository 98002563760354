import { useTranslation } from "react-i18next";
import { Alert, Button } from "@mui/material";
import { FC } from "react";

interface IProps {
  handleActivation: () => void;
}

const ActivationWarning: FC<IProps> = ({ handleActivation }) => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  return (
    <Alert
      severity="warning"
      sx={{
        alignItems: "center",
        "&> .MuiAlert-icon": { flex: "0 0 22px" },
      }}
      action={
        <Button color="inherit" size="small" onClick={handleActivation}>
          {t("activate")}
        </Button>
      }
    >
      {t("notActivatedWarning")}
    </Alert>
  );
};

export default ActivationWarning;
