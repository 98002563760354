export const DEFAULT_LIGHT_BG_COLOR = "#ffffff";
export const DEFAULT_LIGHT_PRIMARY_COLOR = "#000000";
export const DEFAULT_DARK_BG_COLOR = "#121212";
export const DEFAULT_DARK_PRIMARY_COLOR = "#ffffff";

export const DEFAULT_DARK_BORDER_COLOR = "#b3b3b3";
export const CONTRAST_DARK_BORDER_COLOR = "#e8e8e8";

export enum ThemeMode {
  DARK = "dark",
  LIGHT = "light",
}

export const getBorderColor = (
  configBodyBgColor: string | undefined,
  defaultBorderColor: string,
  contrastBorderColor: string
): string =>
  configBodyBgColor === defaultBorderColor
    ? contrastBorderColor
    : defaultBorderColor;
