import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import {
  CustomCodeError,
  EC_INVALID_ISSUER_SYS_NAME,
} from "../../../domain/specs/errorCodes";
import { isInvalidIssuerSysName } from "../../../utils/registration";

export const createOldRouteRedirectLoader =
  (issuerConfigService: IIssuerConfigService) =>
  async (props: LoaderFunctionArgs) => {
    const issuerSysName =
      props.params.issuerSysName || issuerConfigService.getIssuer();

    try {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      const requestUrl = props.request.url;
      const startPath = requestUrl.split(`/${issuerSysName}`)[0] || "";
      const childPath =
        startPath
          .split("/")
          .filter((part) => !!part)
          .slice(-1)[0] || "";
      const pathParams = requestUrl.split(issuerSysName!)[1] || "";

      return redirect(
        `${window.location.origin}/${issuerSysName}/${childPath}${pathParams}`
      );
    } catch (e: any) {
      throw e;
    }
  };
