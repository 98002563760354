import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, Skeleton, Typography } from "@mui/material";
import type { IPromoCodeEntity } from "../../domain/entities/PromocodeEntity";

const VISIBLE_PROMO_CODES_COUNT = 3;

interface IPromoCodesProps {
  loading?: boolean;
  promoCodes?: IPromoCodeEntity[];
}

const PromoCodesBox: FC<IPromoCodesProps> = ({ loading, promoCodes = [] }) => {
  const { t } = useTranslation("translation", { keyPrefix: "common" });
  const { t: tProfile } = useTranslation("translation", {
    keyPrefix: "profile",
  });

  const [isManyItems, setIsManyItems] = useState(
    promoCodes.length > VISIBLE_PROMO_CODES_COUNT
  );

  const handleShowAllClick = () => {
    setIsManyItems((prevState) => !prevState);
  };

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded" width="100%" height={100} />
    );
  }

  if (!promoCodes?.length) {
    return null;
  }

  return (
    <div>
      <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
        {tProfile("promoCodes")}
      </Typography>
      <Box
        sx={{
          p: 1,
          maxHeight: !isManyItems ? "100%" : "410px",
          overflowY: "auto",
        }}
      >
        {promoCodes.map((promoCode, idx) => (
          <Card
            sx={{ mb: idx === promoCodes.length - 1 ? 0 : 2 }}
            key={promoCode.id}
          >
            <CardContent>
              <Typography variant="h5" component="p">
                {promoCode.name.toUpperCase()}
              </Typography>
              <Typography color="text.secondary">
                {promoCode.description}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box sx={{ mt: 1, cursor: "pointer" }}>
        {promoCodes.length > VISIBLE_PROMO_CODES_COUNT && (
          <span onClick={handleShowAllClick}>
            {t(isManyItems ? "showAll" : "hide")}
          </span>
        )}
      </Box>
    </div>
  );
};

export default PromoCodesBox;
