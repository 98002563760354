import { Control, Controller } from "react-hook-form";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Box, FormHelperText } from "@mui/material";
import { isUndefined } from "lodash";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";

export interface InputProps {
  control: Control<FormData, any>;
}

const StyledMuiOtpInput = styled(MuiOtpInput)`
  & input {
    &:-webkit-autofill {
      transition: background-color 5s 3600s;
    }
  }
`;

const lengthMessage = "otp should be 6 symbols";

const validateOptions = {
  required: "otp is required",
  pattern: {
    value: /^\d*$/,
    message: "only numbers are allowed",
  },

  minLength: {
    message: lengthMessage,
    value: 6,
  },
  maxLength: {
    message: lengthMessage,
    value: 6,
  },
};

export const OtpInput: React.FC<InputProps> = ({ control }) => {
  const { t: st } = useTranslation("translation", {
    keyPrefix: "common.errors.otp",
  });
  const validateOptionsWithTranslation = {
    ...validateOptions,
  };

  return (
    <Controller
      control={control}
      rules={{
        ...validateOptionsWithTranslation,
        validate: (value) => {
          if (isUndefined(value)) return false;

          return value.length === 6;
        },
      }}
      render={({ field, fieldState }) => (
        <Box>
          <StyledMuiOtpInput
            sx={{ gap: 1 }}
            {...field}
            value={field.value as unknown as string}
            length={6}
            autoFocus
            inputMode="numeric"
            TextFieldsProps={{
              type: "number",
              inputMode: "numeric",
            }}
          />
          {fieldState.invalid && (
            <FormHelperText error>
              {st(fieldState.error?.type ?? "unknownText")}
            </FormHelperText>
          )}
        </Box>
      )}
      // @ts-ignore
      name="otp"
    />
  );
};
