import { HTTPStatic, IHTTPClientResponse } from "@roketus/web-toolkit";
import { IIssuerConfigServiceResponseDTO } from "../../boundary/IIssuerConfigService";

// TODO add new client and replace API to SDK
export const fetchConfigAPI = async function (
  issuerToken: string
): Promise<IIssuerConfigServiceResponseDTO> {
  const client = HTTPStatic.create({
    staticConfig: {
      baseURL: "https://pwa-config.roket.us",
      headers: {
        "x-api-key": process.env.REACT_APP_PARAM_API_KEY as string,
      },
    },
  });
  const responseFeatures: IHTTPClientResponse<{
    data: IIssuerConfigServiceResponseDTO;
  }> = await client.get("/getconfig", {
    params: {
      env: `${
        process.env.REACT_APP_ENVIRONMENT as string
      }/web-card/${issuerToken}`,
    },
  });

  return responseFeatures.data.data;
};
