import { FC, DetailedHTMLProps, ButtonHTMLAttributes } from "react";
import cn from "classnames";
import styles from "./styles.module.scss";
import { CircularProgress } from "@mui/material";

interface IButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  title?: string;
  onClick?: () => void;
  loading?: boolean;
}

export const Button: FC<IButtonProps> = ({
  onClick,
  title,
  loading,
  ...props
}) => {
  return (
    <button className={cn(styles.c_button)} onClick={onClick} {...props}>
      {loading ? (
        <CircularProgress size="24px" sx={{ color: "#C3C3C3" }} />
      ) : (
        title
      )}
    </button>
  );
};
