import { FC, ReactNode } from "react";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import MessageIcon from "@mui/icons-material/Chat";
import Box from "@mui/material/Box";
import { getIssuerConfigService } from "../../../diContainer/getDependencies";
import ondeLogo from "../../images/onde-logo.png";

interface IProps {
  type: "loyalty" | "message" | "onde";
}

const logoByIssuer = {
  onde: ondeLogo,
};

const getIssuerLogo = (issuerSysName: keyof typeof logoByIssuer): ReactNode => (
  <div
    style={{
      width: "160px",
      margin: "10px 0 15px",
      lineHeight: 1,
    }}
  >
    <img src={logoByIssuer[issuerSysName]} alt={`${issuerSysName} Logo`} />
  </div>
);

const getIconComponent = (type: string) => {
  switch (type) {
    case "message":
      return <MessageIcon fontSize="large" />;
    case "loyalty":
    default:
      return <LoyaltyIcon fontSize="large" />;
  }
};

export const DescriptionLogo: FC<IProps> = ({ type = "loyalty" }) => {
  const issuerConfigService = getIssuerConfigService();

  const issuerSysName = issuerConfigService.getIssuer();

  const iconComponent =
    issuerSysName && Object.keys(logoByIssuer).includes(issuerSysName)
      ? getIssuerLogo(issuerSysName as keyof typeof logoByIssuer)
      : getIconComponent(type);

  return <Box>{iconComponent}</Box>;
};
