import type { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import type { ErrorMessageBus, MessageBus } from "@roketus/web-toolkit";
import { getContainer } from "./container";
import type { IRegistry } from "../boundary/IRegistry";
import type { IRouter } from "../boundary/IRouter";
import type { IRouterService } from "../boundary/IRouterService";
import type { ISignInService } from "../boundary/ISignInService";
import type { ISignupService } from "../boundary/ISignUpService";
import type { IGoogleAnalyticsService } from "../boundary/IAnalyticsService";
import type { IAppStylingService } from "../boundary/IAppStylingService";
import type { IAuthService } from "../boundary/IAuthService";
import type { ICardService } from "../boundary/ICardService";
import type { IIssuerConfigService } from "../boundary/IIssuerConfigService";
import type { ILocalizationService } from "../boundary/ILocalizationService";
import type { IProfileService } from "../boundary/IProfileService";
import type { IActionService } from "../boundary/IActionService";

export function getContainerDependency<T>(name: keyof IRegistry) {
  return getContainer().getDependency(name) as T;
}

export function getSdk() {
  return getContainerDependency<ISDK>("sdk");
}

export function getMessageBus() {
  return getContainerDependency<MessageBus>("messageBus");
}

export function getErrorMessageBus() {
  return getContainerDependency<ErrorMessageBus>("errorMessageBus");
}

export function getRouter() {
  return getContainerDependency<IRouter>("router");
}

export function getRouterService() {
  return getContainerDependency<IRouterService>("routerService");
}

export function getAuthService() {
  return getContainerDependency<IAuthService>("authService");
}

export function getSigninService() {
  return getContainerDependency<ISignInService>("signInService");
}

export function getSignupService() {
  return getContainerDependency<ISignupService>("signupService");
}

export function getIssuerConfigService() {
  return getContainerDependency<IIssuerConfigService>("issuerConfigService");
}

export function getProfileService() {
  return getContainerDependency<IProfileService>("profileService");
}

export function getCardService() {
  return getContainerDependency<ICardService>("cardService");
}

export function getActionService() {
  return getContainerDependency<IActionService>("actionService");
}

export function getLocalizationService() {
  return getContainerDependency<ILocalizationService>("localizationService");
}

export function getGoogleAnalyticsService() {
  return getContainerDependency<IGoogleAnalyticsService>(
    "googleAnalyticsService"
  );
}

export function getAppStylingService() {
  return getContainerDependency<IAppStylingService>("appStylingService");
}
