import type { IMessage } from "../../boundary/IMessage";

export interface AnalyticMessageEntity extends IMessage {
  type: "analyticsEvent";
  message: "";
  source: string;
  data: {
    action: string;
    meta?: Record<string, string>;
  };
}

export const isAnalyticEvent = (
  message: any
): message is AnalyticMessageEntity => {
  return message.type === "analyticsEvent";
};

export const buildAnalyticMessageEntity = ({
  action,
  source,
  meta,
}: {
  action: string;
  source: string;
  meta?: Record<string, string>;
}): AnalyticMessageEntity => {
  let entity: AnalyticMessageEntity = {
    type: "analyticsEvent",
    message: "",
    source,
    data: {
      action,
      meta,
    },
  };

  return entity;
};
