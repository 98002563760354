import { FC } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { getActionService } from "../../../../diContainer/getDependencies";
import discountIcon from "../../../icons/discount.svg";
import { DemoatomicLayout } from "./DemoatomicLayout";
import couponImage from "./images/coupon-bg.svg";
import ActionButtonGroup from "./ActionButtonGroup";

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
}

export const DemoatomicCouponAction: FC<IProps> = ({
  onSubmit,
  onCancel,
}) => {
  const actionService = getActionService();

  const actionData = useObservable(actionService.data$);

  return (
    <DemoatomicLayout
      icon={discountIcon}
      title="couponTitle"
      image={couponImage}
			imageProps={{ 
				backgroundPosition: "bottom 20px center",
			}}
      className="couponPage"
    >
      <ActionButtonGroup
				confirmType="button"
        disabled={actionData?.loading}
        loading={actionData?.loading}
        handleConfirm={onSubmit}
        handleCancel={onCancel}
      />
    </DemoatomicLayout>
  );
};
