import { FC, ReactNode } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

export interface IWrapperProps {
  children?: ReactNode;
  isSurvey?: boolean;
}

export const Wrapper: FC<IWrapperProps> = ({ isSurvey, children }) => {
  return (
    <div
      className={cn(
        styles.c_modal,
        isSurvey && "c_modal__isSurvey",
        "c_modal__isOpen"
      )}
    >
      <div className={styles.c_modal__content}>
        <>{children}</>
      </div>
    </div>
  );
};
