import { ISurveyDTO, ISurveyDataDTO } from "@roketus/loyalty-end-user-js-sdk";
import { ISurveyFormPayload } from "../../../boundary/forms/IFreeSurveyFormData";

export const toSurveyPayloadDTO = (
  surveyFormPayload: ISurveyFormPayload
): ISurveyDTO => {
  const data: ISurveyDataDTO[] = surveyFormPayload.surveyData.map(
    (surveyDataItem) => ({
      type: "questions",
      attributes: {
        description: surveyDataItem.questionDescription,
        answers: surveyDataItem.questionAnswers,
      },
    })
  );

  return { description: surveyFormPayload.surveyDescription, data };
};
