import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MessageIcon from "@mui/icons-material/Chat";
import { useIsAuthenticated } from "../../../adapters/hooks/useIsAuthenticated";

export const NotificationActionDescription: React.FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action",
  });

  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Typography variant="body1" align="center" gutterBottom>
        {t("thanksForScanning")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("thanksForChoosing")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("valuableCustomer")}
      </Typography>
      <Box>
        <MessageIcon fontSize="large" />
      </Box>
      <Typography variant="h5" align="center" gutterBottom>
        {t("reward")}
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        {t("notification.reward")}
      </Typography>
      {!isAuthenticated && (
        <Typography variant="body1" align="center" gutterBottom>
          {t("notification.leavePhone")}
        </Typography>
      )}
    </>
  );
};
