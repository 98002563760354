import { isEmpty } from "lodash";
import { StateSubject } from "@roketus/web-toolkit";
import type { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import type {
  IIssuerConfigData,
  IIssuerConfigService,
} from "../../boundary/IIssuerConfigService";
import { getSdk } from "../../diContainer/getDependencies";
import {
  CustomCodeError,
  EC_EMPTY_CONFIG,
  EC_FAILED_FETCH_CONFIG_DATA,
} from "../../domain/specs/errorCodes";
import { pushErrorToFaro } from "../../utils/faroLogs";
import { fetchConfigAPI } from "../api/fetchIssuerConfig";

const stateMachine = new StateSubject<IIssuerConfigData>({
  loading: false,
  isLoaded: false,
  failed: false,
  config: {},
});

const setIssuer: IIssuerConfigService["setIssuer"] = (issuerToken, sdk) => {
  sdk = sdk || getSdk();
  sdk.setIssuer(issuerToken);
};

const getIssuer: IIssuerConfigService["getIssuer"] = (sdk) => {
  sdk = sdk || getSdk();
  return sdk.getIssuer();
};

const fetchConfig: IIssuerConfigService["fetchConfig"] = async function (
  issuerToken
) {
  stateMachine.setState({
    loading: true,
  });

  try {
    const config = (await fetchConfigAPI(issuerToken)) || {};

    if (isEmpty(config)) {
      throw new CustomCodeError(EC_EMPTY_CONFIG);
    }

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      config,
    });
  } catch (e) {
    pushErrorToFaro(e, { message: "Error: failed to fetch issuer config" });

    stateMachine.setState({
      loading: false,
      isLoaded: true,
      failed: true,
    });

    throw new CustomCodeError(EC_FAILED_FETCH_CONFIG_DATA);
  }
};

export const init = function (sdk: ISDK): IIssuerConfigService {
  return {
    setIssuer: (issuerToken) => setIssuer(issuerToken, sdk),
    getIssuer: () => getIssuer(sdk),
    fetchConfig,
    data$: stateMachine.state$,
  };
};
