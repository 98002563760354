import { FC, useMemo, useState } from "react";
import { useForm, UseFormSetError } from "react-hook-form";
import { isUndefined, noop } from "lodash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IOTPFormData } from "../../boundary/IOTPFormData";
import { getAuthService } from "../../diContainer/getDependencies";
import { Box, Button, Grid, Typography, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { LayoutHeadless } from "./layouts/LayoutHeadless";
import { OtpInput } from "./form/OtpInput";
import { ResendOtpButton } from "./ResendOtpButton";

const DISPLAY_LAST_NUMBERS_QUANTITY = 4;

interface Props {
  onSendOtpClick: (
    otp: string,
    setError: UseFormSetError<IOTPFormData>
  ) => void;
  onResendActivationCode?: () => void;
  phoneNumber: string;
  isLoading: boolean;
  isSkipable?: boolean;
  onSkipStep?: () => void;
  canEditPhone?: boolean;
}

export const ValidateOtp: FC<Props> = ({
  onSendOtpClick,
  phoneNumber,
  onResendActivationCode,
  isLoading,
  isSkipable = false,
  onSkipStep,
  canEditPhone = true,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "signIn.validateOtp",
  });
  const navigate = useNavigate();

  const authService = getAuthService();

  const [isResendClicked, setResendClicked] = useState(false);
  const phoneNumberSlice = useMemo(() => {
    const phoneLength = phoneNumber.length;
    if (phoneLength < DISPLAY_LAST_NUMBERS_QUANTITY) return phoneNumber;
    const startIdx = phoneLength - DISPLAY_LAST_NUMBERS_QUANTITY;
    return phoneNumber.slice(startIdx, phoneLength);
  }, [phoneNumber]);

  const hasCodeResend = !isUndefined(onResendActivationCode);
  const handleResendCode = hasCodeResend
    ? () => {
        isSkipable && setResendClicked(true);

        onResendActivationCode && onResendActivationCode();
      }
    : noop;

  const handleChangePhoneNumber = () => {
    authService.removeTokens();
    navigate(-1);
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setError,
  } = useForm<IOTPFormData>({
    mode: "onChange",
  });

  const onSubmit = (data: IOTPFormData) => {
    onSendOtpClick(data.otp, setError);
  };

  return (
    <LayoutHeadless>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            {t("header")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t("text", { phoneNumberSlice })}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: canEditPhone ? "space-between" : "flex-end",
              alignItems: "center",
              height: 40,
            }}
          >
            {canEditPhone &&
              <Button
                sx={{ minWidth: 173 }}
                onClick={handleChangePhoneNumber}
                color="primary"
              >
                {t("changePhoneNumber")}
              </Button>
            }

            {hasCodeResend && (
              <ResendOtpButton handleResend={handleResendCode} />
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OtpInput
              // @ts-ignore
              control={control}
            />
          </form>
        </Grid>
        <Grid item xs={12} alignContent="space-around">
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            variant="contained"
            fullWidth
            loading={isLoading}
          >
            {t("buttonLabel")}
          </LoadingButton>
        </Grid>
        {isSkipable && isResendClicked && (
          <Grid item container xs={12} justifyContent="flex-end">
            <Link component="button" onClick={onSkipStep}>
              {t("notReceivingOtp")}
            </Link>
          </Grid>
        )}
      </Grid>
    </LayoutHeadless>
  );
};
