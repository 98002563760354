import { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LinkIcon from "@mui/icons-material/Link";
import CopyIcon from "@mui/icons-material/ContentCopy";
import PopoverAutoClosing from "./PopoverAutoClosing";
import { copyText } from "../../utils/utils";

interface Props {
  type?: "input" | "link";
  url?: string | null;
  handleSuccess?: () => void;
  handleError?: (e: any) => void;
}

const CopyLink: FC<Props> = ({
  type = "link",
  url = "",
  handleSuccess,
  handleError,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "common" });

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleCopyClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (url) {
      try {
        copyText(url);

        setAnchorEl(e.currentTarget);

        handleSuccess && handleSuccess();
      } catch (e) {
        handleError && handleError(e);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {type === "input" ? (
        <TextField
          id="copy-link-input"
          name="copy-link-input"
          value={url}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            readOnly: true,
            inputProps: {
              style: { flex: "1 1 0" },
            },
            startAdornment: (
              <InputAdornment position="start" sx={{ height: "auto" }}>
                <LinkIcon color="primary" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end" sx={{ height: "auto" }}>
                <CopyIcon color="primary" />
                <Button
                  variant="text"
                  sx={{
                    whiteSpace: "nowrap",
                    ml: "8px",
                    textTransform: "none",
                  }}
                  onClick={handleCopyClick}
                >
                  {t("copy")}
                </Button>
              </InputAdornment>
            ),
          }}
        />
      ) : (
        <span
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            display: "inline"
          }}
          role="link"
          onClick={handleCopyClick}
        >
          {url}
        </span>
      )}

      <PopoverAutoClosing anchorEl={anchorEl} onClose={handleCloseSnackbar}>
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("copiedMessage")}
        </Alert>
      </PopoverAutoClosing>
    </>
  );
};

export default CopyLink;
