import { useObservable } from "@roketus/web-toolkit";
import { getAuthService } from "../../diContainer/getDependencies";

export const useIsAuthenticated = (): boolean => {
  const authService = getAuthService();
  const authData = useObservable(authService.data$);

  //TODO: extract isAuthorized into a separate hook.
  return !!authData?.isAuthenticated && !!authData?.isAuthorized;
};
