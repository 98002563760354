import cn from "classnames";
import styles from "./styles.module.scss";
import imgSrc from "./2.png";

export const Banner: React.FC = () => {
  return (
    <div className={cn(styles.c_banner)}>
      <img src={imgSrc} alt="" />
    </div>
  );
};
