import { FC, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import styled from "@emotion/styled";
// import { isAndroidOSMobile } from "../../utils/utils";
import { Paper, Typography } from "@mui/material";
import CopyLink from "./CopyLink";

const Container = styled(Paper)(({ theme }) => ({
  backgroundColor:
    (theme as any)?.palette?.mode === "light" ? "#F5F5F5" : "inherit",
  width: "100%",
  padding: "1.5rem 1rem",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  "& .no-wrap": {
    whiteSpace: "nowrap",
  },
}));

const InAppBrowserWarning: FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "inAppBrowserWarning",
  });
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}`;

  useEffect(() => {
    // if (isAndroidOSMobile()) {
    //   window.location.href =
    //     "intent://card.dev.roket.us/usdemodev#Intent;scheme=https;package=com.android.chrome;end";
    // }
  }, []);

  return (
    <Container>
      <Typography variant="h6">{t("warningTitle")}</Typography>
      <Typography variant="body1">{t("copyMessage")}</Typography>
      <CopyLink url={currentUrl} />
      <Typography variant="body1">
        <Trans
          i18nKey="orMessage"
          t={t}
          components={{
            span: <span className="no-wrap"></span>,
          }}
        />
      </Typography>
    </Container>
  );
};

export default InAppBrowserWarning;
