import type { MetaAttributes, MetaUser } from "@grafana/faro-react";
import { faro } from "..";
import { isResponseError } from "./response-errors";
import { CustomCodeError } from "../domain/specs/errorCodes";

export function pushEventToFaro(
  message: string,
  context?: Record<string, string>
) {
  faro.api.pushEvent(message, context);
}

export function pushLogToFaro(
  message: string,
  context?: Record<string, string>
) {
  faro.api.pushLog([message], { context });
}

export function pushErrorToFaro(
  error: any,
  context: Record<string, string> = {}
) {
  let errorStack = "";
  let errorText = "";
  let errorCode = "unknown";

  if (typeof error === "string") {
    errorText = error;
    errorCode = "unknown";
  } else if (isResponseError(error)) {
    errorText = error.response.data.data[0]?.title;
    errorCode = error.response.data.data[0]?.error_code;
  } else if ("response" in error && typeof error.response?.data === "string") {
    errorText = String(error.response?.data);
    errorCode = String(error.response?.status);
  } else if (error instanceof CustomCodeError) {
    errorText = error.message;
    errorCode = "Custom Error Code";
    errorStack = String(error.stack);
  } else if (error instanceof Error) {
    errorText = error.message;
    errorCode = error.name;
    errorStack = String(error.stack);
  } else {
    errorText = context.message ?? "";
    errorCode = context.errorCode ?? "unknown";
  }

  let payloadError;

  if (errorText) {
    payloadError = new Error(errorText);
  } else {
    payloadError = error as Error;
  }

  faro.api.pushError(payloadError, { context: { errorStack, errorCode, ...context } });
}

export function setUserMetaToFaro(
  key: keyof MetaUser,
  value: string & MetaAttributes
) {
  if (!faro.config.user) faro.config.user = {};
  faro.config.user[key] = value;
}

export function setUserAttributesMetaToFaro(
  key: keyof MetaAttributes,
  value: string & MetaAttributes
) {
  if (!faro.config.user) faro.config.user = {};
  if (!faro.config.user.attributes) faro.config.user.attributes = {};
  faro.config.user.attributes[key] = value;
}
