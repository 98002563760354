import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as UAAndroidWalletButtonSvg } from "./uk_add_to_google_wallet_add-wallet-badge.svg";
import { ReactComponent as USAndroidWalletButtonSvg } from "./enUS_add_to_google_wallet_add-wallet-badge.svg";

export const AndroidWalletButton: FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const { i18n } = useTranslation("translation");

  const AndroidWalletButtonSvg =
    i18n.language === "uk"
      ? UAAndroidWalletButtonSvg
      : USAndroidWalletButtonSvg;

  return (
    <AndroidWalletButtonSvg
      style={{
        width: "100%",
        height: "auto",
        pointerEvents: "initial",
        cursor: "pointer",
      }}
      onClick={onClick}
    />
  );
};
