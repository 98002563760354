import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { regExpIsNameValid } from "../../../domain/specs/validationRules";
import { getMessageBus, getSignupService } from "../../../diContainer/getDependencies";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import { LayoutHeadless } from "../../components/layouts/LayoutHeadless";
import { SignupButtonWithLoading } from "../../components/SignupButtonWithLoading";
import { Input } from "../../components/form/Input";
import { Grid, Typography } from "@mui/material";

type TFormData = { firstName: string; lastName: string };

export const SignupProfilePage: FC = () => {
  const { control, handleSubmit, formState } = useForm<TFormData>({
    mode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  const { t } = useTranslation("translation", {
    keyPrefix: "signUp.setProfile",
  });
  const { t: tError } = useTranslation("translation", {
    keyPrefix: "common.errors",
  });

  const canSetProfile = formState.isValid;

  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const onSubmit = ({ firstName, lastName }: TFormData) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SetProfileButtonClick",
      source: "SignUpProfilePage",
    });
    messageBus.send(analyticEvent);

    signupService.setProfile(firstName, lastName);
  };

  return (
    <LayoutHeadless>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h4">
              {t("header")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              rules={{
                required: {
                  value: true,
                  message: tError("isRequired", {
                    label: t("firstNameLabel") as string,
                  }) as string,
                },
                pattern: {
                  value: regExpIsNameValid,
                  message: tError("firstNameError") as string,
                },
                minLength: {
                  value: 2,
                  message: tError("minLength"),
                },
              }}
              control={control}
              name="firstName"
              label={t("firstNameLabel") as string}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              rules={{
                required: {
                  value: true,
                  message: tError("isRequired", {
                    label: t("lastNameLabel") as string,
                  }) as string,
                },
                pattern: {
                  value: regExpIsNameValid,
                  message: tError("lastNameError") as string,
                },
                minLength: {
                  value: 2,
                  message: tError("minLength"),
                },
              }}
              control={control}
              name="lastName"
              label={t("lastNameLabel") as string}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <SignupButtonWithLoading
              onClick={handleSubmit(onSubmit)}
              disabled={!canSetProfile}
            >
              {t("continueButton")}
            </SignupButtonWithLoading>
          </Grid>
        </Grid>
      </form>
    </LayoutHeadless>
  );
};
